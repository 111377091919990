import {Alert, Box, Button, Container, Fade, TextField, useTheme} from "@mui/material"
import React, {useEffect, useMemo, useRef, useState}              from "react"
import {createQueuedAiMessage, createQueuedUserMessage}           from "../../util/prepkit-utils"
import {cloneDeep}                                         from "lodash"
import {doGet, doPost}                                     from "../../util/do-fetch"
import Grid                                                from "@mui/material/Unstable_Grid2"
import Message                                             from "../prepkit/chat/Message"
import {ArrowForward}                                      from "@mui/icons-material"

const Welcome = ({prepKit, user, userProfile, onComplete}) => {
	const [firstName, setFirstName] = useState(userProfile?.first_name || '')
	const [lastName, setLastName] = useState(userProfile?.last_name || '')
	const [aiPersonality, setAiPersonality] = useState(userProfile?.ai_personality_id)
	const [confirmedName, setConfirmedName] = useState(false)
	const [confirmedLearningStyle, setConfirmedLearningStyle] = useState(false)
	const [aiPersonalities, setAiPersonalities] = useState(null)
	const [error, setError] = useState(null)

	const TYPE = true

	const [introMessage, setIntroMessage]  = useState(createQueuedAiMessage(`Hello and welcome to your **${prepKit ? prepKit.name : ''}** PrepKit! 🎉`, false, TYPE))
	const [introMessageComplete, setIntroMessageComplete] = useState(false)

	const [nameMessage, setNameMessage]  = useState(null)
	const [nameMessageComplete, setNameMessageComplete] = useState(false)

	const [confirmedNameMessage, setConfirmedNameMessage]  = useState(null)

	const [learningStyleMessage, setLearningStyleMessage]  = useState(null)
	const [learningStyleMessageComplete, setLearningStyleMessageComplete] = useState(false)

	const [confirmedLearningStyleMessage, setConfirmedLearningStyleMessage]  = useState(null)

	const [getStartedMessage, setGetStartedMessage]  = useState(null)
	const [getStartedMessageComplete, setGetStartedMessageComplete] = useState(false)

	const containerRef = useRef(null)



	const aiPersonalityName = useMemo(()=>{
		return aiPersonality &&
			aiPersonalities &&
			aiPersonalities.find(p => p.id === aiPersonality)?.name
	}, [aiPersonality, aiPersonalities])


	useEffect(() => {
		fetchAiPersonalities()
	}, [])

	useEffect(() => {
		setTimeout(()=> {
			scrollToBottom()
		}, 250)
	}, [introMessage, nameMessage, learningStyleMessage, getStartedMessageComplete])

	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	useEffect(() => {
		if(introMessageComplete) {
			setIntroMessage(prev => {
				const clone = cloneDeep(prev)
				clone.animate = false
				clone.type = false
				clone.streaming = false
				return clone
			})
			setNameMessage(
				createQueuedAiMessage(`I'm Birdsy, your personal Ai Study Partner and I’m going to help you pass your real estate license exam. Before we get started, please tell me your **first** and **last name**.`, false, true)
			)
		}
	}, [introMessageComplete])

	useEffect(() => {
		if(nameMessageComplete) {
			setNameMessage(prev => {
				const clone = cloneDeep(prev)
				clone.animate = false
				clone.type = false
				clone.streaming = false
				return clone
			})
		}
	}, [nameMessageComplete])


	useEffect(() => {
		if(learningStyleMessageComplete) {
			setLearningStyleMessage(prev => {
				const clone = cloneDeep(prev)
				clone.animate = false
				clone.type = false
				clone.streaming = false
				return clone
			})
		}
	}, [learningStyleMessageComplete])


	useEffect(() => {
		if(confirmedName) {
			setConfirmedNameMessage(
				createQueuedUserMessage(`Yes, my name is ${firstName} ${lastName}.`, true, false)
			)
			setTimeout(()=>{
				const personalitiesMsg = aiPersonalities.map(p => `**${p.name}:** ${p.description}` ).join('\n\n')
				setLearningStyleMessage(
					createQueuedAiMessage(`Thank you, ${firstName}! It's **great** to meet you.

Now, I’d like to customize your learning experience. _Everyone learns differently_, so what’s your preferred learning style?

${personalitiesMsg}

Which one sounds best for you?`, false, TYPE)
				)
			}, 1000)
		}
	}, [confirmedName])

	useEffect(() => {
		if(confirmedLearningStyle) {
			setConfirmedLearningStyleMessage(
				createQueuedUserMessage(`I prefer ${aiPersonalityName}.`, false, false)
			)
			setTimeout(()=>{
				setGetStartedMessage(
					createQueuedAiMessage(`Got it, ${firstName}. I’ll make sure to keep our sessions **${aiPersonalityName}** moving forward!

Now that we’re all set, let’s dive into your exam prep journey. Ready to jump in?`, false, TYPE)
				)
			}, 1000)
		}
	}, [confirmedLearningStyle])


	const fetchAiPersonalities = async () => {
		try {
			const response = await doGet(`/mini/ais`)
			const data = await response.json()
			setAiPersonalities(data)
		}
		catch (err) {
			console.error(err)
		}
	}

	const handleWelcomeComplete = async () => {
		// update user profile
		try {
			const options = {
				body: JSON.stringify({
					first_name: firstName,
					last_name: lastName,
					ai_personality_id: aiPersonality,
					welcomed: true
				})
			}
			const response = await doPost(`/mini/user/profile`, options)

			if(response.ok) {
				const profile = await response.json()
				onComplete(profile)
			}
			else {
				setError("Error saving user profile.")
			}

		}
		catch (err) {
			setError("Error saving user profile.")
		}
	}

	const isAtBottom = () => {
		return (
			window.innerHeight + window.scrollY >=
			document.documentElement.scrollHeight
		)
	}

	const handleScroll = () => {
		if (isAtBottom()) {
			scrollToBottom()
		}
	}

	const scrollToBottom = () => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth", block: "end" })
		}
	}

	const handleTextTyped = () => {
		handleScroll()
	}

	return <Box ref={containerRef} textAlign={"left"} justifyContent={"flex-start"} pb={10}>
				{
					error && <Alert color="error" onClose={()=>setError(null)}>
						{error}
					</Alert>
				}
				{
					introMessage &&
					<Message message={introMessage}
					         onTypingComplete={()=>setIntroMessageComplete(true)}
					         onTextTyped={handleTextTyped}
					/>
				}

				{
					nameMessage &&
					<Message message={nameMessage}
					         onTypingComplete={()=>setNameMessageComplete(true)}
					         onTextTyped={handleTextTyped}
					/>
				}
				{
					nameMessageComplete &&
					!confirmedName &&
					<Fade in timeout={1000}>
						<Box mx={5}>
							<Box display="flex" gap={2}>
								<TextField
									label="First Name"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									variant="outlined"
									fullWidth
									autoFocus={true}
								/>
								<TextField
									label="Last Name"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									variant="outlined"
									fullWidth
								/>
							</Box>
							<Button variant={"outlined"}
							        size={"large"}
							        sx={{mt: 1}}
							        onClick={()=>{
								        setConfirmedName(true)
							        }}
							>
								Confirm Name
							</Button>
						</Box>
					</Fade>
				}
				{
					confirmedNameMessage &&
					<Message message={confirmedNameMessage}
					         onTextTyped={handleTextTyped}
					/>
				}
				{
					learningStyleMessage &&
					<Message message={learningStyleMessage}
					         onTypingComplete={()=>setLearningStyleMessageComplete(true)}
					         onTextTyped={handleTextTyped}
					/>
				}
				{
					learningStyleMessageComplete &&
					!confirmedLearningStyle &&
					<Fade in timeout={1000}>
						<Box mx={5}>
							<Box display="flex" gap={2}>
								{
									aiPersonalities.map(p=><Button
										key={`aip-${p.id}`}
										onClick={()=> {
											setAiPersonality(p.id)
											setConfirmedLearningStyle(true)
										}}
										variant={"outlined"}
										size={"large"}
									>
										{p.name}
									</Button>)
								}
							</Box>
						</Box>
					</Fade>
				}
				{
					confirmedLearningStyleMessage &&
					<Message message={confirmedLearningStyleMessage}
					         onTextTyped={handleTextTyped}
					/>
				}
				{
					getStartedMessage &&
					<Message message={getStartedMessage}
					         onTypingComplete={()=>setGetStartedMessageComplete(true)}
					         onTextTyped={handleTextTyped}
					/>
				}

				{
					getStartedMessageComplete &&
					<Fade in timeout={1000}>
						<Box mx={5}>
							<Box display="flex" width={"100%"} textAlign={"center"} justifyContent={"center"}>
								<Button
									onClick={handleWelcomeComplete}
									variant={"contained"}
									size={"large"}
									sx={{px: 5, py: 2}}
								>
									Let's Get Started!
									<ArrowForward/>
								</Button>
							</Box>
						</Box>
					</Fade>

				}
			</Box>
}

export default Welcome