import React, {useEffect, useMemo, useState} from 'react'
import {
	Alert,
	Box, Button, CircularProgress,
	Container,
	Dialog, DialogActions, DialogContent, IconButton,
	InputAdornment,
	List,
	ListItemButton,
	TextField,
	Typography, useMediaQuery,
	useTheme,
} from '@mui/material'
import Grid                     from '@mui/material/Unstable_Grid2' // Grid version 2
import {useNavigate, useParams} from "react-router-dom"
import {doGet, doPost}          from "../../util/do-fetch"
import {logger}                                                            from "../../util/log-utils"
import {ArrowBack, ArrowForward, AutoAwesome, ChevronRight, Close, Search} from "@mui/icons-material"
import PackageCard                                                         from "./PackageCard"
import PurchasePackage               from "./PurchasePackage"
import MiniSkeleton                  from "./MiniSkeleton"
import BirdsyMiniLogo                from "../BirdsyMiniLogo"
import AuthIntro                     from "../auth/AuthIntro"
import Login                         from "../auth/Login"
import ForgotPassword                from "../auth/ForgotPassword"
import SignUp                        from "../auth/SignUp"
import UpdatePassword                from "../auth/UpdatePassword"
import LeftRightCenter               from "../LeftRightCenter"
import {findFirstTopicWithQuestions} from "../../util/prepkit-utils"
import {cloneDeep}                   from "lodash"
import getSupabase                   from "../../util/supabase-utils"
import LoadingWithMessages           from "../LoadingWithMessages"
import GradientCard                  from "../GradientCard"
import GlassButton                   from "../GlassButton"
import LeftRight                     from "../LeftRight"
import {verifyPurchase}              from "../../util/mini"

const log = logger("MiniShop", 1)

function MiniShop() {

	const navigate = useNavigate()
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const { categoryToken, packageToken, optionId } = useParams()
	const [categories, setCategories] = useState(null)
	const [category, setCategory] = useState(null)
	const [packages, setPackages] = useState(null)
	const [prepKits, setPrepKits] = useState(null)
	const [trialAvailable, setTrialAvailable] = useState(false)
	const [packageToTry, setPackageToTry] = useState(null)
	const [selectedPackage, setSelectedPackage] = useState(null)
	const [selectedOption, setSelectedOption] = useState(null)
	const [filter, setFilter] = useState("")
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const [verifyingPurchase, setVerifyingPurchase] = useState(false)

	useEffect(() => {
		log.debug(`calling fetchData(categoryToken=${categoryToken}, packageToken=${packageToken})`)
		fetchData()
	}, [categoryToken, packageToken])

	useEffect(() => {
		if(packages) {
			const pkg = packages.find(p=>p.token===packageToken)
			const opt = pkg?.options.find(o=>o.id === optionId)
			setSelectedPackage(pkg || null)
			setSelectedOption(opt || null)
		}
	}, [categoryToken, packageToken, optionId, packages])

	const filteredPackages = useMemo(() => {
		return packages &&
				packages.filter(p => p.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1)
	}, [packages, filter])

	const fetchData = async () => {
		try {
			const parts = [`/mini/shop`]
			if(categoryToken) parts.push(categoryToken)
			//if(packageToken) parts.push(packageToken)
			//if(optionId) parts.push(optionId)
			const url = parts.join(`/`)
			console.log(`doGet(${url})`)

			const response = await doGet(url)
			const data = await response.json()

			console.log(data)

			if(data.categories) setCategories(data.categories)
			if(data.category) setCategory(data.category)

			setPackages(data.packages) // always set packages
			setPrepKits(data.prepKits)

			setTrialAvailable(data.trialAvailable)
		}
		catch (err) {
			log.error(err)
		}
	}

	const handleClick = (pkg) => {
		if(trialAvailable) {
			setPackageToTry(pkg)
		}
		else {
			navigate(pkg.token)
		}
	}

	const handleTryPackage = async () => {
		try {
			const response = await doPost(`/mini/package/${packageToTry.id}/try`)

			if (response.ok) {
				const data = await response.json()
				const prepKit = data.prepKit
				if(prepKit) {
					navigate(`/prepkit/${prepKit.id}`)
				}
				else {
					throw `Your complimentary study session has expired.`
				}
			}
			else if (response.status === 403) {
				// forbidden
				throw `Your complimentary study session has expired.`
			}
		}
		catch (err) {
			log.error(err)
			setError(err)
		}
	}

	const handleSelectPackage = (selectedPackage) => {
		console.log(`select package ${selectedPackage?.token}`)
		if(selectedPackage) {
			navigate(`/prep/${categoryToken}/${selectedPackage.token}`)
		}
	}

	const handleSelectOption = (selectedPackage, selectedOption) => {
		console.log(`select option ${selectedPackage?.token}, ${selectedOption?.id}`);

		let url = `/prep/${categoryToken}`;

		if (selectedPackage?.id) {
			url += `/${selectedPackage.token}`;

			if (selectedOption?.id) {
				url += `/option/${selectedOption.id}`;
			}
		}

		navigate(url);
	};

	const handlePurchaseComplete = async (paymentIntent) => {
		setVerifyingPurchase(true)

		try {
			const license = await verifyPurchase(paymentIntent)

			if (license) {
				navigate(`/prepkit/${license.prepkit_id}`)
			} else {
				navigate(`/prepkit`)
			}
		} finally {
			setVerifyingPurchase(false)
		}
	}

	if(verifyingPurchase) {
		return <Container maxWidth="lg">
			<Grid xs={12} md={4} spacing={3} justifyContent="center" py={3} sx={{textAlign: "center"}}>
				<LoadingWithMessages messages={[
							"Processing payment...",
							"Verifying purchase...",
							"Generating license...",
							"Creating PrepKit..."
						]} duration={3000}/>

			</Grid>
		</Container>
	}

	if(packages) {
		return (
			<Container maxWidth="lg">
				<Grid xs={12} spacing={3} justifyContent="center" py={3}>
					<Box display={"flex"}
					     justifyContent={"center"}
					     alignItems={"center"}
					     ml={-7}
					>
						<IconButton onClick={()=>navigate('..')} sx={{mr: 1}}>
							<ArrowBack fontSize={"large"}/>
						</IconButton>
						<Typography variant={isSmallScreen ? "h5" : "h2"} style={{textAlign: 'center'}}>
							{category.name} PrepKits
						</Typography>
					</Box>
				</Grid>
				<Grid xs={12} spacing={3}>
					<Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} pb={3}>
						<TextField
							autoComplete="off"
							fullWidth={true}
							name="package-filter"
							variant="standard"
							style={{
								display: "block",
								borderRadius: "30px",
								width: "100%",
								maxWidth: "800px",
								padding: isSmallScreen ? "5px 15px" : "15px",
								border: "1px solid " + theme.palette.divider
							}}
							placeholder="Find your PrepKit..."
							value={filter}
							onChange={(event) => {
								setFilter(event.target.value)
							}}
							InputProps={{
								disableUnderline: true,
								endAdornment: <InputAdornment position="end">
									<Search/>
								</InputAdornment>,
							}}
						/>
					</Box>
				</Grid>
				{
					error &&
					<Alert severity={"error"}>
						{error}
					</Alert>
				}
				<Grid xs={12} spacing={3}>
					<Box maxWidth={"800px"} margin={"0 auto"}>
						{filteredPackages && filteredPackages.map((pkg) => (
							<Grid item="true"
							      key={`package-${pkg.id}`}
							      border={"0px solid red"}
							      paddingBottom={2}
							>
								<PackageCard packageData={pkg}
								             actionLabel={trialAvailable ? "Try" : "Select"}
								             onClick={handleClick}
								             variant={isSmallScreen ? "small" : ""}
								/>
							</Grid>
						))}
					</Box>
				</Grid>
				{
					prepKits &&
					<Grid xs={12} spacing={3} pt={2}>
						<Box maxWidth={"800px"} margin={"0 auto"}>
							<Box p={2} border={`1px solid ${theme.palette.divider}`} borderRadius={1}>
								<Typography variant="h5" sx={{mb: 1}}>Your PrepKits</Typography>
								<List>
									{prepKits && prepKits.map((pk) => (
										<ListItemButton key={`prepKit-${pk.id}`} onClick={()=>navigate(`/prepkit/${pk.id}`)}>
											{pk.name}
										</ListItemButton>
									))}
								</List>
							</Box>
						</Box>
					</Grid>
				}
				{
					selectedPackage &&
					<PurchasePackage
						packages={[selectedPackage]}
						selectedPackage={selectedPackage}
						selectedOption={selectedOption}
						onSelectPackage={handleSelectPackage}
						onSelectOption={handleSelectOption}
						onCancel={() => {
							navigate(`../${categoryToken}`)
						}}
						onPurchaseComplete={handlePurchaseComplete}
					/>
				}
				{
					packageToTry &&
					<Dialog
						open={packageToTry}
					>
						<DialogContent sx={{width: 500}}>
							<LeftRightCenter

								left={
									<Box p={2}>
										<Close sx={{color: "transparent"}}/>
									</Box>
								}
								center={
									<Box display="flex" justifyContent="center" pb={2}>
										<BirdsyMiniLogo width={125}/>
									</Box>
								}
								right={
									<Box>
										<Button size={"small"} sx={{mt: 1}} onClick={()=>setPackageToTry(null)}>
											<Close color={"error"}/>
										</Button>
									</Box>
								}
							/>
							<Box display={"flex"}
							     justifyContent={"center"}
							     flexDirection={"column"}
							     pt={2}
							     px={2}
							>
								<Typography variant={"h6"}>Try PrepKit</Typography>
								<Typography>
									You're about to start your complimentary study session
									with the
									<b>
										&nbsp;{packageToTry.name}&nbsp;
									</b>
									PrepKit.  Ready?
								</Typography>
							</Box>
						</DialogContent>
						<DialogActions>
							<Box display={"flex"}
							     justifyContent={"center"}
							     alignItems={"center"}
							     flexDirection={"column"}
							     width={"100%"}
							     pb={2}
							>
								<Button sx={{width: '200px', py: 1, mt: 2}}
								        disabled={loading}
								        variant={"outlined"}
								        color={"success"}
								        onClick={handleTryPackage}
								>
									Let's Go &rarr;
								</Button>
							</Box>
						</DialogActions>
					</Dialog>
				}
			</Container>
		)
	}
	else if(categories) {
		return <Container maxWidth="lg" sx={{mt: {xs: 3, sm: 3, md: 10}}}>
			<Grid container spacing={3} justifyContent={"center"}>
				{
					categories.map(c=>(
						<Grid key={`cat-${c.id}`} xs={12} md={4} sx={{ order: { xs: 3, md: 1 }, textAlign: 'center' }}>
							<GradientCard
								title={c.name}
								caption={c.packages && `${c.packages.length} PrepKits`}
								color={"white"}
								backgroundColor1={c.color}
								backgroundColor2={c.color2}
								onClick={() => navigate(`/prep/${c.token}`)}
							>
								<Box display={"flex"}
								     flex={1}
								     mt={isSmallScreen ? 0 : 10}
								     justifyContent={{xs: "flex-start", sm: "flex-start", md: "center" }}
								     alignItems={"center"}
								>
									<GlassButton onClick={()=>{}}
									             bullet={false}
									             label={"View PrepKits"}
									             icon={<ArrowForward sx={{ml: 1}}/>}
									/>
								</Box>
							</GradientCard>
						</Grid>
					))
				}
			</Grid>
		</Container>

	}


}

export default MiniShop
