import React                                       from 'react'
import {Box, Chip, IconButton, Tooltip, useTheme,} from '@mui/material'
import LeftRight                                   from "../LeftRight"
import {MoveUp}                               from "@mui/icons-material"

function TopicsTree({topics, selectedTopic, onClick, onMoveTopicUp}) {

	const theme = useTheme()

	const renderTopics = (_topics, depth) => {
		if (!_topics || _topics.length === 0) return null

		return (
			<ul style={{
				paddingLeft: depth ? "25px" : "10px",
				listStyle: "default",
			}}>
				{_topics.map((t, i) => {
					const isLeaf = t.hasOwnProperty('is_leaf')
						? t.is_leaf === true
						: !t.topics

					const questionsCount = isLeaf
						? (t.questions && t.questions.length) || 0
						: 0

					const questionsLabel = `${questionsCount} / ${t.target_question_count}`
					const questionsTooltip = `${questionsCount} questions, ${t.target_question_count} targeted`
					const complete = questionsCount >= t.target_question_count
					const selected = selectedTopic && selectedTopic.id === t.id
					const chipColor = questionsCount > 0 && questionsCount >= t.target_question_count
						? "success"
						: "default"

					return (
						<li style={{
								padding: "5px",
								paddingRight: "0px",
								fontWeight: t.parent_id === null ? "bold" : "normal",
								borderBottomStyle: "solid",
								borderBottomColor: theme.palette.divider,
								borderBottomWidth: depth === 0 ? "1px" : "0px"
							}}
						    key={t.id + ":" + i}
						>
							<div style={{
								padding: "3px",
								cursor: "pointer",
								fontWeight: selected || depth === 0
									? "bold"
									: "normal",
							}}
							     onClick={() => {
								     onClick && onClick(t)
							     }}
							>
								<LeftRight
									left={
										<Box>
											{t.name}
											{
												isLeaf &&
												<Tooltip title={questionsTooltip} enterDelay={1000} enterNextDelay={1000}>
													<Chip
														sx={{ml: 1}}
														label={questionsLabel}
														size="small"
														variant={selected ? "covered" : "outlined"}
														color={chipColor}
													/>
												</Tooltip>
											}
										</Box>
									}
									right={
										<Box>

											{
												onMoveTopicUp&&
												depth > 0 &&
													<Tooltip title={"Move up one level"}>
														<IconButton onClick={()=>onMoveTopicUp(t)}><MoveUp/></IconButton>
													</Tooltip>
											}
										</Box>
									}
								/>

							</div>
							{/*
								(!topic.topics || topic.topics.length === 0)
								&& <Button style={{padding: "0px"}}
								           onClick={() => generateQuestionsForTopic(topic.id)}>Gen</Button>
							*/}
							{renderTopics(t.topics, depth + 1)}
						</li>
					)
				})}
			</ul>
		)
	}

	return renderTopics(topics, 0, onClick)
}

export default TopicsTree
