import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const RouteLogger = ({enabled}) => {
	const location = useLocation()

	useEffect(() => {
		if(enabled) {
			console.log(`Navigation to: ${location.pathname}`)
			console.trace('Navigation initiated by:')
		}
	}, [location, enabled])

	return null
}

export default RouteLogger
