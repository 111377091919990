import {useNavigate}                          from "react-router-dom"
import '../stylesheets/Navbar.css'
import React, {useState}                      from "react"
import {
	AppBar,
	Box,
	Button,
	CssBaseline,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	useTheme
}                                                                                    from "@mui/material"
import AccountCircle                                                                 from "@mui/icons-material/AccountCircle"
import ThemeToggle                                                                   from "./ThemeToggle"
import {
	BookmarkBorder,
	CircleOutlined,
	Dashboard,
	ExpandMore,
	Home, Login,
	Logout,
	Menu as MenuIcon, Pending, Remove,
	Shop, Support
} from "@mui/icons-material"
import useMediaQuery                                                                 from "@mui/material/useMediaQuery"
import {useUserStore}                                                                from "../state"
import BirdsyLogo                                                                    from "./BirdsyLogo"
import BirdsyMiniLogo                                                                from "./BirdsyMiniLogo"
import useUserRoles                                                                  from "../hooks/useUserRoles"
import LeftRight                                                                     from "./LeftRight"
import {ADMIN_ACTIONS}                                                               from "../util/admin"

const Navbar = React.forwardRef((
	{currentTheme, toggleTheme }, ref
) => {

	const isAdmin = useUserRoles(['admin'])
	const {user, signOut} = useUserStore()
	const theme = useTheme()
	const navigate = useNavigate()
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const [accountMenu, setAccountMenu] = useState(false)
	const [navMenuAnchorEl, setNavMenuAnchorEl] = useState(null)
	const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null)

	const logout = () => {
		setAccountMenu(false)
		signOut()
	}

	const login = () => {
		navigate('/login')
	}

	const createAccount = () => {
		navigate('/login/signUp')
	}

	const launchSupportEmail = () => {
		const currentUrl = window.location.href
		const body = `



------------------------------------------
Page: ${currentUrl}
User: ${user.email}
Identifier: ${user.id}
		`
		const mailtoLink = `mailto:hello@birdsy.ai?subject=Birdsy%20Support&body=${encodeURIComponent(body)}`
		window.location.href = mailtoLink
	}

	const handleNavMenuClick = (event) => {
		setNavMenuAnchorEl(event.currentTarget)
	}
	const handleNavMenuClose = () => {
		setNavMenuAnchorEl(null)
	}

	return (
		<>
			<AppBar key={"appbar"}
			        ref={ref} position={"fixed"} elevation={0} color="transparent"
			        sx={{background: 'transparent'}}>
				<CssBaseline/>
				<Toolbar>
					<Box style={{cursor: "pointer", paddingTop: 5}} onClick={() => navigate('/')}>
						<BirdsyMiniLogo width={130}/>
					</Box>
					<Box className={"nav-menu-container"}>
						<Box className={"nav-menu-left"}>

						</Box>
					</Box>
					<Box className={"account-menu-container"}>
						<IconButton
							size="large"
							onClick={handleNavMenuClick}
							aria-controls="nav-menu"
							aria-haspopup="true"
						>
							<MenuIcon />
						</IconButton>

						{
							user
							? <Menu
								anchorEl={navMenuAnchorEl}
								id="nav-menu"
								open={(navMenuAnchorEl !== null)}
								onClose={handleNavMenuClose}
								onClick={handleNavMenuClose}
								transformOrigin={{horizontal: 'right', vertical: 'top'}}
								anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
							  >
								<MenuItem onClick={() => navigate(`/`)}>
									<ListItemIcon>
										<Home fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Home"/>
								</MenuItem>
								<MenuItem onClick={() => navigate(`/prepkit`)}>
									<ListItemIcon>
										<Dashboard fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="My PrepKits"/>
								</MenuItem>
								<MenuItem onClick={() => navigate(`/prep`)}>
									<ListItemIcon>
										<Shop fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Shop PrepKits"/>
								</MenuItem>
								<MenuItem onClick={() => navigate(`/q`)}>
									<ListItemIcon>
										<Pending fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Question Code"/>
								</MenuItem>

								{
									isAdmin && <Box>
										<Divider/>
										<MenuItem disabled={true}>
											<ListItemText secondary={"Admin"}/>
										</MenuItem>
										{
											ADMIN_ACTIONS.map(action => {
													return (<MenuItem
														key={`mnu-admin-${action.label}`}
														onClick={() => navigate(action.url)}>
														<ListItemIcon>
															<Remove fontSize="small"/>
														</ListItemIcon>
														<ListItemText primary={action.label}/>
													</MenuItem>)
												}
											)
										}
									</Box>
								}

								<Divider/>

								<MenuItem
									onClick={launchSupportEmail}
								>
									<ListItemIcon>
										<Support />
									</ListItemIcon>
									<ListItemText primary="Support" />
								</MenuItem>

								<Divider/>

								{user &&
								<MenuItem onClick={() => navigate(`/account/profile`)}>
									<ListItemIcon>
										<AccountCircle fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Profile"/>

								</MenuItem>
								}
								{user &&
								<MenuItem onClick={logout}>
									<ListItemIcon>
										<Logout fontSize="small"/>
									</ListItemIcon>
									<ListItemText primary="Sign Out"/>
								</MenuItem>
								}

								<Divider/>

								<MenuItem onClick={(evt)=>evt.stopPropagation()}>
									<ThemeToggle toggleTheme={toggleTheme}/>
								</MenuItem>

								<Divider/>

								<MenuItem disabled={true} sx={{textAlign: "center"}}>
									{user.email}
								</MenuItem>

							  </Menu>
							: <Menu
									anchorEl={navMenuAnchorEl}
									id="nav-menu"
									open={(navMenuAnchorEl !== null)}
									onClose={handleNavMenuClose}
									onClick={handleNavMenuClose}
									transformOrigin={{horizontal: 'right', vertical: 'top'}}
									anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
								>
									<MenuItem onClick={login}>
										<ListItemText primary="Login"/>
									</MenuItem>

									<MenuItem onClick={createAccount}>
										<ListItemText primary="Create an Account"/>
									</MenuItem>
								</Menu>
						}

					</Box>

				</Toolbar>
			</AppBar>
			<Toolbar key={"tbar"} className="navbar-background"/> {/* Empty Toolbar to push content down */}
		</>
	)
})

export default Navbar