import React, {useMemo, useState, useEffect} from 'react'
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip, Collapse,
	List,
	ListItem,
	ListItemButton,
	Typography, useMediaQuery,
	useTheme
} from '@mui/material'
import Slide from '@mui/material/Slide'
import LeftRight                  from "../LeftRight"
import LeftRightCenter            from "../LeftRightCenter"
import Score                      from "./Score"
import {
	ArrowForward,
	Check,
	CircleOutlined,
	Close,
	ExpandLess,
	ExpandMore
}                                 from "@mui/icons-material"
import {useNavigate}              from "react-router-dom"
import {findFirstQuestionInTopic} from "../../util/mini"
import SectionSummary             from "../SectionSummary"
import ClickLink                  from "../ClickLink"
import {convertToRgba}            from "../../util/utils"

const SectionCard = ({section, index, onClickStart, onClickRestart, onClickResume}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const defaultElevation = section && section.percentAnswered ? 2 : 2
	const [elevation, setElevation] = useState(defaultElevation)
	const [expand, setExpand] = useState(false)
	const localStorageKey = `section-expand-${section.id}`; // Unique key for each section

	const shadeBackgroundColor = useMemo(()=>{
		return convertToRgba(theme.palette.divider, .025)
	}, [theme.palette.divider])

	// load the expand state from localStorage when the component mounts
	useEffect(() => {
		const savedExpandState = localStorage.getItem(localStorageKey);
		if (savedExpandState !== null) {
			setExpand(JSON.parse(savedExpandState)); // Restore the state as a boolean
		}
	}, [localStorageKey]); // Effect runs when the component mounts

	// save expand state to localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem(localStorageKey, JSON.stringify(expand));
	}, [expand, localStorageKey]);

	if (!section) return null

	return (
		<Box
		      sx={{
			      backgroundColor: theme.palette.background.paper,
		      }}
		      //onMouseOver={() => setElevation(6)}
		      //onMouseOut={() => setElevation(2)}
		      elevation={elevation}
		      id={`tour-section-card-${index}`}
		>
			<Box
				sx={{
					border: `0px solid  magenta`,
					py: 2,
					flex: '1 0 auto',
				}}
			>
				<Box pb={0}
				     display={"flex"}
				     flexDirection={"row"}
				     justifyContent={"space-between"}
				     alignItems={"center"}
				     border={"0px solid red"}
				>
					<Typography gutterBottom
					            sx={{
						            fontSize: 20,
						            fontWeight: 500,
						            m: 0,
						            border: "0px solid green"
					            }}
					>
						{section.name}
					</Typography>

					<ScoreButton topic={section}
					             onClickStart={onClickStart}
					             onClickRestart={onClickRestart}
					             onClickResume={onClickResume}
					/>

				</Box>

				<Box mt={1} border={"0px solid magenta"}>
					<SectionSummary section={section}
					                expanded={expand}
					                onClick={()=>setExpand(!expand)}
					/>
				</Box>

				<Collapse in={expand}>
					<Box sx={{
						border:`1px solid ${theme.palette.divider}`,
						borderRadius: 1,
						mt: expand ? 2 : 0
					}}
					>
						{
							expand &&
							section.topics &&
							section.topics.length &&
							<List sx={{p: 0}}>
								{
									section.topics.map(t => (
										<TopicListItem key={`topic-${t.id}`}
										               topic={t}
										               shadeColor={shadeBackgroundColor}
										/>
									))
								}
							</List>
						}
					</Box>
				</Collapse>
			</Box>
		</Box>
	)
}

const TopicListItem = ({topic, shadeColor}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const navigate = useNavigate()
	const [expand, setExpand] = useState(false)
	const localStorageKey = `topic-expand-${topic.id}` // Unique key for each topic

	// load the expand state from localStorage when the component mounts
	useEffect(() => {
		const savedExpandState = localStorage.getItem(localStorageKey);
		if (savedExpandState !== null) {
			setExpand(JSON.parse(savedExpandState)); // Restore the state as a boolean
		}
	}, [localStorageKey]); // Effect runs when the component mounts

	// save expand state to localStorage whenever it changes
	useEffect(() => {
		localStorage.setItem(localStorageKey, JSON.stringify(expand));
	}, [expand, localStorageKey]);


	const handleClickQuestion = (question) => {
		navigate(`q/${question.code}`)
	}

	const handleStartTopic = (topic) => {
		let question = findFirstQuestionInTopic(topic)
		question && navigate(`q/${question.code}`)
	}

	const handleResumeTopic = (topic) => {
		let question = findFirstQuestionInTopic(topic, (q) => !q.userAnswer)
		if (!question) {
			question = findFirstQuestionInTopic(topic)
		}
		question && navigate(`q/${question.code}`)
	}

	return <ListItem
		sx={{border: "0px solid red", borderBottom: `1px solid ${theme.palette.divider}`, p: 0}}>
		<Box display={"flex"} flex={1} flexDirection={"column"}>
			<Box p={ isSmallScreen ? 1 : 3}
			     pr={ isSmallScreen ? 1 : 2}
			     pb={expand ? 0 : 2}
			>
				<Box display={"flex"}
				     alignItems={"flex-start"}
				     justifyContent={"space-between"}
				>
					<Typography fontSize={"16px"}
					            fontWeight={500}
					            sx={{
									pb: 1,
									border: "0px solid red"
								}}
					>
						{topic.name}
					</Typography>

					<ScoreButton topic={topic}
					             onClickStart={handleStartTopic}
					             onClickRestart={handleStartTopic}
					             onClickResume={handleResumeTopic}
					/>
				</Box>

				<LeftRight
					left={
						<ClickLink
							onClick={() => setExpand(!expand)}
							sx={{fontSize: "14px"}}
						>
							<Box display={"flex"} alignItems={"center"}>
								{topic.questionCount} Questions
								{
									expand
										? <ExpandLess fontSize={"small"} sx={{color: theme.palette.text.primary}}/>
										: <ExpandMore fontSize={"small"} sx={{color: theme.palette.text.primary}}/>
								}
							</Box>
						</ClickLink>
					}
					right={
						null
					}
				/>

				<Collapse in={expand} sx={{ml: -3, mr: -2}}  >
					<Box py={0} sx={{
						border: "0px solid red",
						mt: expand ? 2 : 0,
						backgroundColor: theme.palette.background.paper
					}}
					>
						{
							topic.questions &&
							topic.questions.length &&
							<List sx={{p: 0}}>
								{
									topic.questions.map(q => {
										const icon = q.userAnswer
											? q.userAnswer.is_correct
												? <Check sx={{mr: 2}} color={"success"} fontSize={"small"}/>
												: <Close sx={{mr: 2}} color={"error"} fontSize={"small"}/>
											: <CircleOutlined sx={{mr: 2, opacity: 0}} fontSize={"small"}/>

										return <ListItemButton key={`question-${q.id}`}
										                       onClick={() => handleClickQuestion(q)}
										                       sx={{
																   borderTop: `1px dotted ${theme.palette.divider}`,
											                       backgroundColor: shadeColor,
											                       fontSize: '14px',
											                       py: 1.5
															   }}>
											{icon}
											{q.question}
										</ListItemButton>
									})
								}
							</List>
						}
					</Box>
				</Collapse>
			</Box>
		</Box>
	</ListItem>
}

const ScoreButton = ({topic, onClickStart, onClickResume, onClickRestart}) => {

	const isComplete = useMemo(() => {
		return topic && topic.percentAnswered === 1
	}, [topic])

	return <Chip
		className={"tour-section-start-button"}
		variant={"outlined"}
		onClick={()=>{
			if(topic.answeredCount === 0) {
				onClickStart(topic)
			}
			else if(topic.answeredCount === topic.questionCount) {
				onClickRestart(topic)
			}
			else {
				onClickResume(topic)
			}
		}}
		sx={{
			ml: 2,
			px: isComplete ? 0 : 1,
			py: 2
		}}
		label={
			<Box display={"flex"}
			     alignItems={"center"}
			     borderRadius={50}
			>
				{
					isComplete &&
					<Box ml={-0.6}>
						<Score variant={"inline"} topic={topic} sx={{mr: 1}}/>
					</Box>
				}
				<Box>
				{
					topic.answeredCount === 0
						? "Start"
						: topic.answeredCount === topic.questionCount
							? "Restart"
							: "Resume"
				}
				</Box>
				<ArrowForward sx={{fontSize: 16, ml: 0.5}}/>
			</Box>
		}
	/>
}

export default SectionCard
