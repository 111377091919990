import React, { useState, useCallback, useRef, useEffect } from 'react'
import {
	TextField,
	Button,
	Container,
	Box,
	Typography,
	useTheme,
	InputAdornment,
	IconButton,
	Alert,
	AlertTitle,
	Divider
}                                                      from '@mui/material'
import HCaptcha                                        from '@hcaptcha/react-hcaptcha'
import { getSupabase }                                 from "../../util/supabase-utils"
import { ArrowBack, Check, Visibility, VisibilityOff } from "@mui/icons-material"
import { useUserStore }                                from "../../state"
import {isBlankOrEmpty}                                from "../../util/utils"
import ClickLink                                       from "../ClickLink"
import {setIntendedUrl}                                from "../../util/mini"

const supabase = getSupabase()

const SignUp = ({ switchView }) => {
	const theme = useTheme()
	const { user } = useUserStore()
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [captchaToken, setCaptchaToken] = useState('')
	const [error, setError] = useState('')
	const [success, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [resendLoading, setResendLoading] = useState(false)
	const [resendSuccess, setResendSuccess] = useState(false)
	const [lastResendTime, setLastResendTime] = useState(null)
	const [showAccountExists, setShowAccountExists] = useState(false)
	const resendCooldown = 30 // seconds
	const captchaRef = useRef(null)
	const captchaSiteKey = process.env.REACT_APP_HCAPTCHA_SITE_KEY

	const resetCaptcha = useCallback(() => {
		if (captchaRef.current) {
			captchaRef.current.resetCaptcha()
		}
		setCaptchaToken('')
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (captchaRef.current) {
				captchaRef.current.execute()
			}
		}, 1000)
		return () => clearTimeout(timer)
	}, [])

	const handleSignUpOrSignIn = useCallback(async () => {

		const flds = []
		if(isBlankOrEmpty(firstName)) flds.push("First Name")
		if(isBlankOrEmpty(lastName)) flds.push("Last Name")
		if(isBlankOrEmpty(email)) flds.push("Email")
		if(isBlankOrEmpty(password)) flds.push("Password")

		if (flds.length > 0) {
			setError(`Please complete the following fields: ${flds.join(', ')}`)
			return
		}
		if (!captchaToken) {
			setError('Please complete the captcha to verify you are not a bot.')
			return
		}

		try {
			setLoading(true)
			setError('')

			setIntendedUrl()

			const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
				email,
				password,
				options: {
					captchaToken,
					data: {
						name: `${firstName} ${lastName}`,
					},
					emailRedirectTo: `${process.env.REACT_APP_SITE_URL}/auth/callback`
				},
			})

			if (signUpError) throw signUpError

			// this is a workaround because Supabase will not throw an error if the
			// account already exists.
			// @see https://github.com/supabase/auth/issues/1517#issuecomment-976200828
			if(signUpData?.user?.identities?.length === 0) {
				setShowAccountExists(true)
				return
			}

			setSuccess(signUpData.user && !signUpData.session)

			if (signUpData.user) {
				// Update the user_profile with the first and last name
				const { error: updateError } = await supabase
					.from('user_profile')
					.update({
						first_name: firstName,
						last_name: lastName
					})
					.eq('user_id', signUpData.user.id); // Match the user_id from the auth response

				if (updateError) {
					console.error('Error updating user profile:', updateError.message);
				} else {
					console.log('User profile updated successfully');
				}
			}

			if (signUpData.session) window.location.reload()
		} catch (error) {
			console.error('Sign up/Sign in error:', error.message)
			setError("An error occurred. Please try again later.")
		} finally {
			setLoading(false)
			resetCaptcha()
		}
	}, [email, password, firstName, lastName, captchaToken, resetCaptcha])

	const handleResendVerification = useCallback(async () => {
		const now = Date.now()
		if (lastResendTime && now - lastResendTime < resendCooldown * 1000) {
			const remainingTime = Math.ceil((resendCooldown * 1000 - (now - lastResendTime)) / 1000)
			setError(`Please wait ${remainingTime} second${remainingTime !== 1 ? 's' : ''} before resending.`)
			return
		}

		if (!captchaToken) {
			setError('Please complete the captcha to verify you are not a bot.')
			return
		}

		try {
			setResendLoading(true)
			setError('')

			setIntendedUrl()

			const { error } = await supabase.auth.resend({
				email,
				type: "signup",
				options: {
					captchaToken,
					emailRedirectTo: `${process.env.REACT_APP_SITE_URL}/auth/callback`
				}
			})
			if (error) throw error
			setResendSuccess(true)
			setLastResendTime(now)
		} catch (error) {
			setError("Failed to resend verification email. Please try again.")
		} finally {
			setResendLoading(false)
			resetCaptcha()
		}
	}, [email, captchaToken, lastResendTime, resetCaptcha])

	return (
		<Container>
			{
				error &&
				<Alert my={1} severity="error">{error}</Alert>
			}
			{
				showAccountExists &&
				<Alert my={1} severity="info" onClose={()=>setShowAccountExists(false)}>
					<AlertTitle>Account Already Exists</AlertTitle>
					An account with the email address {email} already exists.
					<br/><br/>
					Please <ClickLink onClick={()=>switchView('login')}>login here</ClickLink>.
				</Alert>
			}
			{resendSuccess && <Typography my={1} color="success.main" variant="body2">Confirmation email resent successfully!</Typography>}
			{success ? (
				<Box display="flex" flexDirection="column">
					<Alert color="success">
						<AlertTitle>Success</AlertTitle>
						Account created successfully! Please check your email <b>{email}</b> to confirm your account.
						<Divider sx={{my: 2}}/>
						<Box display="flex" justifyContent="flex-start" alignItems="center">
							<Typography variant="body2">Didn't get the email? Check your spam folder or click Resend.</Typography>
							<Button
								sx={{ml: 1}}
								variant="outlined"
								size="small"
								onClick={handleResendVerification}
								disabled={resendLoading}
							>
								{resendLoading ? "Resending..." : "Resend"}
							</Button>
						</Box>
					</Alert>
				</Box>
			) : (
				<Box component="form" mt={3}>
					<Typography variant="h6" sx={{cursor: 'pointer'}} onClick={() => switchView('')} display="flex" alignItems="center">
						<ArrowBack sx={{mr: 0.5}}/>
						Register
					</Typography>
					<TextField
						label="First Name"
						fullWidth
						margin="normal"
						value={firstName}
						disabled={loading}
						onChange={(e) => setFirstName(e.target.value)}
					/>
					<TextField
						label="Last Name"
						fullWidth
						margin="normal"
						value={lastName}
						disabled={loading}
						onChange={(e) => setLastName(e.target.value)}
					/>
					<TextField
						label="Email"
						fullWidth
						margin="normal"
						value={email}
						disabled={loading}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						label="Password"
						type={showPassword ? 'text' : 'password'}
						fullWidth
						margin="normal"
						value={password}
						disabled={loading}
						onChange={(e) => setPassword(e.target.value)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setShowPassword(!showPassword)}
										onMouseDown={(event) => event.preventDefault()}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<Button
						variant="outlined"
						size="large"
						color="primary"
						disabled={loading}
						fullWidth
						sx={{ py: 2, mt: 2 }}
						onClick={handleSignUpOrSignIn}
					>
						{loading ? "Creating Account" : "Continue"}
					</Button>
				</Box>
			)}
			<Box mt={3} mb={2}>
				<Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
					<HCaptcha
						ref={captchaRef}
						size="invisible"
						sitekey={captchaSiteKey}
						onVerify={setCaptchaToken}
						theme={theme.palette.mode}
						onError={setError}
					/>
				</Box>
				{captchaToken && (
					<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
						<Check color="success" fontSize="small" />
						<Typography variant="body2" ml={1}>
							Verified Human
						</Typography>
					</Box>
				)}
			</Box>
		</Container>
	)
}

export default SignUp