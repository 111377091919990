import {Box, Fab, InputBase, useTheme} from "@mui/material"
import {ArrowForward}               from "@mui/icons-material"
import React, {useEffect, useState} from "react"


const QuestionCode = ({size, defaultCode, autoFocus=true, onSubmit}) => {

	const [isSmall] = useState(size === "small")
	const theme = useTheme()
	const [code, setCode] = useState(defaultCode || '')
	const [error, setError] = useState(null)

	useEffect(()=> {
		if(defaultCode) {
			handleButtonClick()
		}
	}, [defaultCode])

	const handleInputChange = (event) => {
		const value = event.target.value.toUpperCase() // Convert to uppercase
		const alphanumericValue = value.replace(/[^A-Z0-9]/gi, '') // Remove non-alphanumeric characters
		if (alphanumericValue.length <= 5) { // Limit to 5 characters
			setCode(alphanumericValue)
		}
	}

	const handleButtonClick = async () => {
		if (code && code.length === 5) {
			onSubmit(code)
		} else {
			setError("Question code invalid.")
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleButtonClick()
		}
	}

	return <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
		{
			!isSmall && <Box width={80} height={80}></Box>
		}
		<InputBase
			value={code}
			onChange={handleInputChange}
			onKeyDown={handleKeyDown}
			autoFocus={autoFocus}
			placeholder="_____"
			sx={{
				fontSize: isSmall ? '20px' : '32px',
				fontFamily: 'monospace',
				borderRadius: isSmall ? '30px' : '50px',
				backgroundColor: theme.palette.background.paper,
				border: `1px solid ` + (error ? theme.palette.error.light : theme.palette.divider),
				padding: isSmall ? '7px 35px' : '10px 30px',
				width: '100%',
				maxWidth: '500px',
				letterSpacing: '20px',
				display: 'flex',
				textAlign: 'center',
				justifyContent: 'center',
				alignItems: 'center',
				ml: isSmall ? 0 : 2,
				mr: isSmall ? 1 : 2
			}}
			inputProps={{ maxLength: 5, sx: {textAlign: 'center'} }}
		/>
		<Box width={80} height={80}>
			<Fab
				onClick={handleButtonClick}
				sx={{
					marginTop: '12px',
					fontSize: '32px',
					boxShadow: 'none',
				}}
			>
				<ArrowForward style={{ fontSize: '32px' }} />
			</Fab>
		</Box>
	</Box>
}

export default QuestionCode