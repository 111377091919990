import React, {useState, useRef, useMemo} from 'react'
import {
	TextField,
	Button,
	Link,
	Container,
	Box,
	Typography,
	Divider,
	useTheme,
	InputAdornment,
	IconButton, Alert, AlertTitle
}                                                        from '@mui/material'
import {Email, EmailOutlined, Visibility, VisibilityOff} from '@mui/icons-material'
import HCaptcha                                          from '@hcaptcha/react-hcaptcha'
import { getSupabase }                                   from "../../util/supabase-utils"
import Google                                            from "../icons/Google"
import LeftRight                                         from "../LeftRight"
import {useLocation}                                     from "react-router-dom"
import {setIntendedUrl}                                  from "../../util/mini"

const supabase = getSupabase()

const AuthIntro = ({ switchView }) => {
	const theme = useTheme()
	// ?error=server_error
	// &error_code=500
	// &error_description=Database+error+saving+new+user#error=server_error
	// &error_code=500
	// &error_description=Database+error+saving+new+user
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const error = queryParams.get('error')
	//const errorCode = queryParams.get('error_code')
	const errorDescription = queryParams.get('error_description')

	const serverError = useMemo(()=>{
		return error === "server_error"
	}, [error])


	const handleGoogleLogin = async () => {
		setIntendedUrl()

		const signInConfig = {
			provider: 'google',
			options: {
				redirectTo: `${process.env.REACT_APP_SITE_URL}/auth/callback`
			}
		}

		console.log(JSON.stringify({signInConfig}, null, 2))

		const { error } = await supabase.auth.signInWithOAuth(signInConfig)
		if (error) console.error('Google login error:', error.message)
	}

	return (
		<Container>
			<Box component="form" my={2}>
				{
					serverError &&
					<Alert severity={"error"} sx={{mb: 2}}>
						<AlertTitle>Error</AlertTitle>
						{errorDescription}
					</Alert>
				}
				<Typography variant="body" lineHeight={1.1} sx={{fontSize: '1.1em'}}>
					Register to access your complimentary study session.
				</Typography>

				<Box display="flex" flexDirection="column">
					<Button variant={"outlined"}
					        size="large"
					        fullWidth
					        sx={{ mt: 2, py: 2  }}
					        onClick={handleGoogleLogin}
					>
						<Google />
						<Box sx={{ ml: 1 }}>Continue with Google</Box>
					</Button>
				</Box>

				<Button variant="outlined"
				        size="large"
				        fullWidth
				        sx={{ mt: 2, py: 2 }}
				        onClick={()=>switchView('signUp')}
				>
					<EmailOutlined color={"info"} sx={{opacity: 0.5}} />
					<Box sx={{ ml: 1 }}>Create an Account</Box>
				</Button>

				<Box
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					pt={2}
					pb={1}
				>
					<Box sx={{ width: '100%' }}>
						<Divider />
					</Box>
					<Box sx={{ width: '140%', fontSize: '.8em', color: theme.palette.text.disabled, textAlign: 'center' }}>
						Already registered?
					</Box>
					<Box sx={{ width: '100%' }}>
						<Divider />
					</Box>
				</Box>

				<Button size="large"
				        fullWidth
				        onClick={()=>switchView('login')}
				>
					Login to Continue
				</Button>

			</Box>
		</Container>
	)
}

export default AuthIntro
