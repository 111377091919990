import React, {useEffect, useState} from 'react'
import {
	Box,
	Button, Chip, CircularProgress,
	Container, Divider, FormControlLabel,
	IconButton,
	Link, Menu, MenuItem,
	Stack, Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import moment                                                                            from 'moment'
import {Archive, ArrowBack, Check, CheckCircle, CheckCircleOutline, MoreVert, Unarchive} from "@mui/icons-material"
import {useNavigate}                                                                     from "react-router-dom"
import {doGet, doPost}                                                                   from "../../util/do-fetch"
import {DEBUG, logger}                                                                   from "../../util/log-utils"
import HoverTableRow                                                                     from "../HoverTableRow"
import BirdsyLogo                                                                        from "../BirdsyLogo"
import BirdsyMiniLogo                                                                    from "../BirdsyMiniLogo"
import BirdsyMiniMark                                                                    from "../BirdsyMiniMark"
import LeftRight                                                                         from "../LeftRight"

const log = logger("Products", DEBUG)

function Products() {
	const navigate = useNavigate()
	const theme = useTheme()
	const [products, setProducts] = useState([])
	const [filtered, setFiltered] = useState([])
	const [showArchived, setShowArchived] = useState(false)

	useEffect(() => {
		fetchProducts()
	}, [])


	useEffect(() => {
		if(products){
			setFiltered(showArchived ? products : products.filter(p => p.archived_at === null))
		}
		else {
			setFiltered([])
		}
	}, [products, showArchived])


	const fetchProducts = async () => {
		try {
			const response = await doGet(`/admin/products?includeArchived=true`)
			const data = await response.json()
			setProducts(data)
		}
		catch (err) {
			log.error(err)
		}
	}

	const handleUpdate = async (p) => {
		setProducts(prev => [...prev].map(o => o.id === p.id ? p : o))
	}

	const handleArchive = async (p) => {
		try {
			const response = await doPost(`/admin/products/${p.id}/archive`)
			const data = await response.json()
			setProducts(prev => [...prev].map(o => o.id === data.id ? data : o))
		}
		catch (err) {
			log.error(err)
		}
	}

	const handleUnarchive = async (p) => {
		try {
			const response = await doPost(`/admin/products/${p.id}/unarchive`)
			const data = await response.json()
			setProducts(prev => [...prev].map(o => o.id === data.id ? data : o))
		}
		catch (err) {
			log.error(err)
		}
	}


	return (
		<Container maxWidth="lg">
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
					<IconButton onClick={() => navigate(`/admin`)}>
						<ArrowBack/>
					</IconButton>
					<Typography variant={"h5"}>
						Products
					</Typography>
				</Box>

				<Stack direction={"row"} spacing={1}>
					<FormControlLabel
						style={{marginRight: '15px'}}
						control={
							<Switch checked={showArchived}
							        onChange={() => setShowArchived(!showArchived)}
							/>
						}
						label="Show Archived"
					/>
					<Button variant="contained" onClick={() => navigate('/admin/products/create')}>
						Create Product
					</Button>
				</Stack>
			</Stack>

			<TableContainer style={{border: "1px solid #ccc", borderRadius: '5px', marginTop: "10px", marginBottom: '125px'}}>
				<Table sx={{minWidth: 650, backgroundColor: theme.palette.background.paper}} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell component="th">Name</TableCell>
							<TableCell component="th" align="center">Questions</TableCell>
							<TableCell component="th" align="right">Created</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filtered && filtered.map((row) => {
							return <ProductTableRow
										key={row.id}
										product={row}
										onUpdate={handleUpdate}
										onArchive={handleArchive}
										onUnarchive={handleUnarchive}

								/>
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	)
}

function ProductTableRow({product, onUpdate, onArchive, onUnarchive}) {

	const navigate = useNavigate()
	const hasAllQuestions = product.total_question_count >= product.target_question_count
	const generating = !hasAllQuestions && product.generate_questions_at
	const [anchorEl, setAnchorEl] = useState(null)
	const [menuOpen, setMenuOpen] = useState(false)

	if (!product) return null

	return <HoverTableRow
		key={product.id}
		onClick={() => navigate(`/admin/products/${product.id}`)}
		sx={{
			'&:last-child td, &:last-child th': { border: 0 },
			cursor: 'pointer'
		}}
	>
		<TableCell >
			<Typography style={{padding: 0, margin: 0}}>
				{product.name}
				{product.archived_at && <Chip sx={{ml: 2}} label="Archived" color="warning" variant="outlined" size="small" />}
			</Typography>
			<small>{product.token}</small>
		</TableCell>

		<TableCell align="center">
			{
				hasAllQuestions
				? <Chip color="success"
				        variant="outlined"
				        label={product.total_question_count}
				        icon={<Check fontSize="small" />}
					/>
				: <Tooltip title={`${product.total_question_count} questions, ${product.target_question_count} targeted`}
				           enterDelay={1000}
				           enterNextDelay={1000}
					>
						<Chip color={generating ? 'info' : 'warning'}
						      variant={generating ? 'contained' : 'outlined'}
						      label={`${product.total_question_count} / ${product.target_question_count}`}
						      icon={generating && <CircularProgress size={14}/>}
						/>

					</Tooltip>
			}
		</TableCell>

		<TableCell align="right">
			{moment(product.created_at)
				.format('M/d/y h:mm a')}
		</TableCell>
		<TableCell align="right">
			<IconButton
				onClick={(event) => {
					event.stopPropagation()
					event.preventDefault()
					setAnchorEl(event.currentTarget)
					setMenuOpen(true)
				}}
			>
				<MoreVert/>
			</IconButton>
			<Menu
				open={menuOpen}
				anchorEl={anchorEl}
				onClose={(event) => {
					event.stopPropagation()
					setAnchorEl(null)
					setMenuOpen(false)
				}}
			>

				{
					product.archived_at
						? <MenuItem onClick={async (event) => {
							event.stopPropagation()
							setMenuOpen(false)
							await onUnarchive(product)
						  }}>
							<Unarchive sx={{mr: 2}}/> Unarchive
						  </MenuItem>
						: <MenuItem onClick={async (event) => {
							event.stopPropagation()
							setMenuOpen(false)
							await onArchive(product)
						  }}>
							<Archive sx={{mr: 2}}/> Archive
						  </MenuItem>
				}
			</Menu>
		</TableCell>
	</HoverTableRow>
}

export default Products
