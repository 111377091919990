import React, { useEffect, useState } from "react"
import ReactMarkdown                  from "react-markdown"
import {typeIt}                       from "../../../util/prepkit-utils"

function TypeIt({ text, markdown, onTypingComplete }) {
	const [typed, setTyped] = useState("")
	const [typing, setTyping] = useState(false)
	const typingSpeed = 170 // ms for each character, adjust as needed

	useEffect(() => {

		setTyped("")
		setTyping(true)

		const typingInterval = typeIt(text,
			typingSpeed,
			(typed) => {
				setTyped((prevTyped) => prevTyped + typed)
			},
			() => {
				clearInterval(typingInterval)
				setTyping(false)
				if (onTypingComplete) {
					onTypingComplete()
				}
			})

		// Cleanup the interval if the component is unmounted
		return () => clearInterval(typingInterval)

	}, [text, onTypingComplete])

	return markdown
		? <ReactMarkdown className="markdown" children={typed + typing ? "▮" : " "}/>
		: <span className="typing">{typed}{typing ? "▮" : " "}</span>
}

export default TypeIt

