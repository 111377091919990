import {useEffect, useRef, useState} from 'react'
import { useNavigate }               from 'react-router-dom'
import getSupabase               from "../../util/supabase-utils"
import {DEBUG, logger} from "../../util/log-utils"
import {Box, Link}     from "@mui/material"
import BirdsyLogo      from "../BirdsyLogo"
import BirdsyMiniMark                            from "../BirdsyMiniMark"
import {getIntendedUrl, getIntendedUrlAndRemove} from "../../util/mini"
import {Alert}                                   from "@mui/lab"

const log = logger("AuthCallback", DEBUG)
const supabase = getSupabase()

const AuthCallback = () => {
	const navigate = useNavigate()
	const [error, setError] = useState(null)
	const hasProcessedRedirectRef = useRef(false)

	useEffect(() => {

		if (hasProcessedRedirectRef.current) return // Prevent duplicate execution
		hasProcessedRedirectRef.current = true

		const processAuth = async () => {
			const { data: { session }, error: err } = await supabase.auth.getSession()

			if (err) {
				console.error('Error retrieving session:', error)
				setError(err)
			}
			else {
				console.log('Session data: ' + session);
				// todo: this should call getIntendedUrlAndRemove but AuthCallback was being called twice
				// todo: which caused it to redirect correctly once, then redirect to / immediately after
				// todo: so I am going to just get the intended URL and leave it there
				// todo: BUT we need to fix this
				const url = getIntendedUrl('/')
				console.log('Redirecting to url: ' + url);
				navigate(url);
			}
		}

		processAuth()
	}, [navigate])

	const launchSupportEmail = () => {
		const currentUrl = window.location.href
		const body = `


------------------------------------------
Page: ${currentUrl}
Issue: ${error}
		`
		const mailtoLink = `mailto:hello@birdsy.ai?subject=Birdsy%20Support&body=${encodeURIComponent(body)}`
		window.location.href = mailtoLink
	}

	return <Box p={5} textAlign={"center"}>
		<BirdsyMiniMark width={50}/>
		Processing authentication...

		{
			error &&
			<Alert severity="error" mb={2} mt={3}>Error signing in.
				Please <Link sx={{cursor: 'pointer'}} onClick={launchSupportEmail}>contact us</Link> and let us know.
			</Alert>
		}
	</Box>
}

export default AuthCallback
