import React, {useState}                                                               from 'react'
import {Box, Button, Card, CardActions, CardContent, IconButton, Typography, useTheme} from '@mui/material'
import {PieChart}                                                                      from "@mui/x-charts"
import Grid                                                           from "@mui/material/Unstable_Grid2"
import {useNavigate}                                                  from "react-router-dom"
import LeftRight                                                      from "../LeftRight"
import {ArrowForward}                                                 from "@mui/icons-material"


const PackageCard = ({packageData, actionLabel, variant, onClick}) => {
	const theme = useTheme()
	const [elevation, setElevation] = useState(2); // Default elevation
	const [small] = useState(variant==="small")

	if(!packageData) return null

	return (
		<Card
			style={{
				width: '100%',
				cursor: 'pointer',
				transition: 'backgroundColor 500ms',
				backgroundColor: elevation === 0 ? theme.palette.background.default : theme.palette.background.paper
			}}
		    onClick={() => onClick && onClick(packageData)}
		    //onMouseOver={() => setElevation(1)}
		    //onMouseOut={() => setElevation(0)}
		    elevation={elevation}
		>
			<CardContent>
				<LeftRight
					left={
						<Box display={"flex"} flexDirection={"column"} border={"0px solid red"}>
							<Box>
								<Typography
									component="div"
									style={{
										fontSize: 16,
										background: `linear-gradient(90deg, ${packageData.category.color}, ${packageData.category.color2})`,
										WebkitBackgroundClip: `text`,
										WebkitTextFillColor: `transparent`,
										backgroundClip: `text`,
										textFillColor: `transparent`,
									}}
								>
									{packageData?.category?.name?.toUpperCase()} PREPKIT
								</Typography>
							</Box>
							<Typography variant={small ? "h6" : "h5"} component="div">
								{packageData.name}
							</Typography>
						</Box>
					}
					right={<Box display={"flex"} border={"0px solid red"}>
							<ArrowForward/>
					</Box>}
				/>
			</CardContent>
		</Card>
	)
}
export default PackageCard