import './stylesheets/App.css'
import './stylesheets/Auth.css'
import {useCallback, useEffect, useMemo, useState}             from "react"
import {createTheme, CssBaseline, GlobalStyles, ThemeProvider} from '@mui/material'
import {createDark, createLight}                               from "./util/theme-colors"
import {AdapterDayjs}                                          from "@mui/x-date-pickers/AdapterDayjs"
import {LocalizationProvider}                                  from "@mui/x-date-pickers"
import AuthGate                                                from "./components/AuthGate"
import ThemeCssInjector                                        from "./components/ThemeCssInjector"
import {Helmet, HelmetProvider}                                from "react-helmet-async"

function App() {
	const [themeName, setThemeName] = useState(
		() => (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
	)

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
		const handleThemeChange = (e) => setThemeName(e.matches ? 'dark' : 'light')

		mediaQuery.addEventListener('change', handleThemeChange)
		return () => mediaQuery.removeEventListener('change', handleThemeChange)
	}, [])

	const theme = useMemo(() => {
		const baseColors = themeName === 'dark' ? createDark() : createLight()
		return createTheme({
			typography: {
				fontFamily: 'Poppins, Arial, sans-serif',
			},
			palette: {
				mode: themeName,
				...baseColors,
			},
		})
	}, [themeName])

	const toggleTheme = useCallback(() => {
		setThemeName((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
	}, [])

	return (
		<HelmetProvider>
			<Helmet>
				<title>Birdsy - Your AI-Powered Exam Study Partner</title>
				<meta
					name="description"
					content="Birdsy helps you prepare for career-defining exams with personalized practice and an adaptive AI tutor. Whether you're studying for your real estate license, a finance certification, or any professional exam, Birdsy tailors your learning experience to focus on your strengths and overcome your challenges. With engaging questions, instant feedback, and a study partner that adapts to your preferred style, Birdsy makes exam prep efficient, effective, and stress-free."
				/>
			</Helmet>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
				<ThemeProvider theme={theme}>
					<GlobalStyles
						styles={{
							html: {
								backgroundColor: theme.palette.background.paper + ' !important',
							},
						}}
					/>
					<ThemeCssInjector/>
					<CssBaseline/>
					<div className={`App ${themeName} env-${process.env.NODE_ENV}`}>
						<AuthGate toggleTheme={toggleTheme}/>
					</div>
				</ThemeProvider>
			</LocalizationProvider>
		</HelmetProvider>
	)
}

export default App
