import {Box, Button, IconButton, Tooltip, Typography, useTheme} from "@mui/material"
import {abcd}                                                   from "../../util/prepkit-utils"
import {ArrowBack, ExpandLess, ExpandMore, Info, InfoOutlined}  from "@mui/icons-material"
import React, {useState}                                        from "react"
import LeftRight                                                from "../LeftRight"
import QuestionTranslator                                       from "../QuestionTranslator"
import useMediaQuery                                            from "@mui/material/useMediaQuery"
import {useNavigate}                                            from "react-router-dom"

const MiniQuestion = ({prepKit, question, topic, parentTopics, answerToShow, loading, collapsible, isPinned, onSend, onAnswer}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const navigate = useNavigate()
	const [expanded, setExpanded] = useState(!collapsible)

	const style = {
		questionContainer: {
			backgroundColor: isSmallScreen ? theme.palette.background.paper : theme.palette.background.default,
			px: isSmallScreen ? 2 : 4 ,
			pt: 2,
			pb: 2,
			borderRadius: isSmallScreen ? 0 : 3,
			border: collapsible
				? `5px solid ${theme.palette.background.paper}`
				: isSmallScreen ? `1px solid ${theme.palette.divider}` :  `none`,
			borderLeftWidth: 0,
			borderRightWidth: 0,
		},
		question: {
			fontSize: isSmallScreen ? "18px" : "24px",
			lineHeight: isSmallScreen ? "22px" : "28px",
		},
		topic: {
			fontWeight: 300,
			textTransform: "uppercase",
			padding: '10px 0 10px 0',
			color: theme.palette.secondary.main
		},
		options: {
			padding: isSmallScreen
				?  isPinned ? 0 : "10px 0 10px 0"
				: "0 0 10px 0",
			marginRight: isSmallScreen ? "-10px" : 0,
			border: "0px solid red"
		},
		option: {
			padding: isSmallScreen ? "3px 0 3px 0" : "7px 0 7px 0"
		},
		optionLetter: {
			display: "inline-block",
			width: isSmallScreen     ? "18px" : "34px",
			height: isSmallScreen    ? "18px" : "34px",
			minWidth: isSmallScreen  ? "18px" : "34px",
			minHeight: isSmallScreen ? "18px" : "34px",
			textAlign: isSmallScreen ? "left" : "center",
			borderRadius: isSmallScreen ? 0 : "25px",
			color: isSmallScreen ? theme.palette.primary.main : theme.palette.text.primary,
			fontSize: isSmallScreen ? "16px" : "18px",
			fontWeight: isSmallScreen ? "500" : "inherit",
			border: isSmallScreen ? "none" : "1px solid" + theme.palette.primary.main,
			padding: isSmallScreen ? "1px 0 0 0" : "3px 4px 4px 4px",
			marginLeft: isSmallScreen ? "-5px" : 0,
			marginTop: isSmallScreen ? "2px" : "3px",
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
			},
			transition: 'background 250ms, borderColor 250ms, color 250ms'
		},
		optionLetterCorrect: {
			borderColor: theme.palette.success.main,
			backgroundColor: isSmallScreen ? "transparent" : theme.palette.success.main,
			color: isSmallScreen ? theme.palette.success.main : theme.palette.success.contrastText
		},
		optionLetterIncorrect: {
			borderColor: theme.palette.error.main,
			backgroundColor: isSmallScreen ? "transparent" : theme.palette.error.main,
			color: isSmallScreen ? theme.palette.error.main : theme.palette.error.contrastText
		},
		optionLabel: {
			flex: 1,
			display: "inline-block",
			fontSize: isSmallScreen ? "16px" : "18px",
			marginLeft: isSmallScreen ? "8px" : "15px",
			paddingTop: isSmallScreen ? "3px" : "7px",
			paddingBottom: isSmallScreen ? "0px" : "5px",
			transition: 'color 250ms',
			border: '0px solid red'
		},
		optionLabelCorrect: {
			color: theme.palette.success.main
		},
		optionLabelIncorrect: {
			color: theme.palette.error.main,
		},
	}

	if (!question) {
		return null
	}

	return (
		<Box
			id={"tour-question"}
			sx={[
				style.questionContainer,
				{
					opacity: loading ? .2 : 1,
					transition: 'opacity 250ms',
				}
			]}
		>
			<Box
				style={style.question}
				display="flex"
				flexDirection={"column"}
				justifyContent="space-between"
				alignItems="flex-end"
				border={"0px solid red"}
				sx={{
					cursor: collapsible ? "pointer" : "default"
				}}
				onClick={() => collapsible && setExpanded(!expanded)}
			>
				{question.question}
				{
					collapsible &&
					<Button size="small">
						{
							expanded
								? "Less"
								: "More"
						}
						{
							expanded
								? <ExpandLess/>
								: <ExpandMore/>
						}
					</Button>
				}
			</Box>
			<Box style={style.options}>
				{question.options.map((o, i) => {
						const answered = answerToShow && answerToShow.answer === i
						const correct = answered && answerToShow.is_correct

						if (!expanded && !answered) {
							return null
						}

						return <Box
							key={`option-${i}`}
							style={style.option}
						>
							<Box
								sx={{
									padding: isSmallScreen ? '3px 4px' : '5px 8px',
									cursor: "pointer",
									borderRadius: isSmallScreen ? '12px' : '25px',
									border: '0px solid red',
									'&:hover': {
										backgroundColor: theme.palette.background.paper,
										border: '0px solid ' + theme.palette.primary.light,
									},
								}}
								onClick={() => {
									if (!answerToShow || answerToShow.answer !== i) {
										onAnswer(question, i, o.label)
									}
								}}
								display="flex"
								flexDirection="row"
								justifyItems="space-between"
								alignItems="flex-start"
							>
								<Box sx={[
									style.optionLetter,
									answered
										? correct
											? style.optionLetterCorrect
											: style.optionLetterIncorrect
										: {}
								]}
								>
									{abcd(i)}
								</Box>
								<Box
									sx={[
										style.optionLabel,
										answered
											? correct
												? style.optionLabelCorrect
												: style.optionLabelIncorrect
											: {}
									]}
								>
									{o.label}
								</Box>
								{
									!isSmallScreen &&
									<Tooltip placement="right" title={`Tell me about "${o.label}"`}>
										<IconButton
											id={`tour-option-help-${i}`}
											sx={{
												mt: isSmallScreen ? "3px" : 0,
												border: "0px solid red"
											}}
											size={isSmallScreen ? "small" : "default"}
											onClick={(event) => {
												event.stopPropagation()
												event.preventDefault()
												onSend(`Tell me more about "${o.label}"`)
											}}
										>
											<InfoOutlined fontSize="14px"/>
										</IconButton>
									</Tooltip>
								}
							</Box>
						</Box>
					}
				)}
			</Box>

			<QuestionTranslator onTranslate={(languageName) => {
				onSend(`Show this question in ${languageName}`)
			}}/>

		</Box>)
}

export default MiniQuestion