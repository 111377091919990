import {Box} from "@mui/material"
import React from "react"

const GlassButton = ({label, bullet=true, icon, onClick}) => {
	return <Box
		px={2}
		py={1}
		mx={-1.5}
		sx={{
			backgroundColor: `rgba(255,255,255,.0)`,
			borderRadius: 1,
			color: 'inherit',
			cursor: 'pointer',
			fontWeight: 600,
			'&:hover': {
				backgroundColor: `rgba(255,255,255,.2)`,
			}
		}}
		display={"flex"}
		flexDirection={"row"}
		onClick={onClick}
	>
		{
			bullet && <Box mr={1}>· </Box>
		}
		<Box>{label}</Box>
		{icon}
	</Box>
}

export default GlassButton