import {ArrowBack, MoreVert, Delete, DeleteForever} from "@mui/icons-material"
import {
	Box,
	Container,
	IconButton,
	Menu,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Button,
	Select,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogActions, Link, Snackbar, Alert, Divider, DialogContent, TextField, useTheme,
} from "@mui/material"
import React, {useEffect, useMemo, useState} from "react"
import moment                                from "moment"
import Grid from "@mui/material/Unstable_Grid2"
import { useNavigate }           from "react-router-dom"
import {doGet, doDelete, doPost} from "../../util/do-fetch"
import { logger }                from "../../util/log-utils"

const log = logger("Users", 1)

function Users() {
	const navigate = useNavigate()
	const theme = useTheme()
	const [users, setUsers] = useState([])
	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(10) // Default page size
	const [totalCount, setTotalCount] = useState(0)
	const [menuAnchor, setMenuAnchor] = useState(null)
	const [selectedUser, setSelectedUser] = useState(null)
	const [error, setError] = useState(null)
	const [showForceDelete, setShowForceDelete] = useState(false)
	const [typeDelete, setTypeDelete] = useState('')
	const isDeleteConfirmed = useMemo(() => {
		return selectedUser &&
			typeDelete === selectedUser.email
	}, [selectedUser, typeDelete])

	useEffect(() => {
		fetchUsers()
	}, [page, pageSize])

	const fetchUsers = async () => {
		try {
			const response = await doGet(`/admin/users?page=${page}&limit=${pageSize}`)
			const data = await response.json()
			setUsers(data.users)
			setTotalCount(data.totalCount)
		} catch (err) {
			log.error(err)
		}
	}

	const handleNextPage = () => {
		if (page * pageSize < totalCount) {
			setPage(page + 1)
		}
	}

	const handlePrevPage = () => {
		if (page > 1) {
			setPage(page - 1)
		}
	}

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value)
		setPage(1) // Reset to first page when page size changes
	}

	const handleCloseForceDelete = () => {
		setTypeDelete("")
		setShowForceDelete(false)
	}

	const handleMenuOpen = (event, user) => {
		setMenuAnchor(event.currentTarget)
		setSelectedUser(user)
	}

	const handleMenuClose = () => {
		setMenuAnchor(null)
		setSelectedUser(null)
	}

	const handleDeleteUser = async () => {
		try {
			const response = await doDelete(`/admin/users/${selectedUser.id}`)
			if(!response.ok) {
				throw response.statusText
			}
			setUsers(users.filter((user) => user.id !== selectedUser.id))
			handleMenuClose()
		} catch (err) {
			setError(`Failed to delete user: ${err?.message || err || "Unknown error"}`)
			log.error(err)
			handleMenuClose()
		}
	}

	const handleForceDeleteUser = async () => {
		try {
			const response = await doPost(`/admin/users/${selectedUser.id}/force-delete`)
			if(!response.ok) {
				throw response.statusText
			}
			setUsers(users.filter((user) => user.id !== selectedUser.id))
			handleCloseForceDelete()
			handleMenuClose()
		} catch (err) {
			setError(`Failed to delete user: ${err?.message || err || "Unknown error"}`)
			log.error(err)
			handleMenuClose()
		}
	}

	return (
		<Container maxWidth="xl">
			<Grid xs={12}>
				<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
					<IconButton onClick={() => navigate(`/admin`)}>
						<ArrowBack />
					</IconButton>
					<Typography variant={"h5"}>Users</Typography>
				</Box>
			</Grid>
			<Grid xs={12}>
				<TableContainer>
					<Table sx={{ border: "1px solid #ccc" }} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Email</TableCell>
								<TableCell align="left">ID</TableCell>
								<TableCell align="right">Email Confirmed</TableCell>
								<TableCell align="right">Last Sign In</TableCell>
								<TableCell align="right">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{users.map((row) => (
								<TableRow key={row.email} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell>
										<Typography>{row.user_metadata?.full_name}</Typography>
										<Typography variant={"body2"}>
											{row.id}
										</Typography>
									</TableCell>
									<TableCell>
										<Link href={`mailto:${row.email}`}>{row.email}</Link>
									</TableCell>
									<TableCell align="right">
										{row.email_confirmed_at ? moment(row.email_confirmed_at).calendar() : "N/A"}
									</TableCell>
									<TableCell align="right">
										{row.last_sign_in_at ? moment(row.last_sign_in_at).calendar() : "N/A"}
									</TableCell>
									<TableCell align="right">
										<IconButton onClick={(e) => handleMenuOpen(e, row)}>
											<MoreVert />
										</IconButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={4} >
				<Button variant="outlined" disabled={page === 1} onClick={handlePrevPage}>
					Previous
				</Button>
				<Box display="flex" alignItems="center" gap={2}>
					<Typography variant="body2">
						Page {page} of {totalCount > 0 ? Math.ceil(totalCount / pageSize) : 1}
					</Typography>
					<Typography variant="body2">Page Size:</Typography>
					<Select
						value={pageSize}
						onChange={handlePageSizeChange}
						size="small"
					>
						<MenuItem value={5}>5</MenuItem>
						<MenuItem value={10}>10</MenuItem>
						<MenuItem value={20}>20</MenuItem>
						<MenuItem value={50}>50</MenuItem>
					</Select>
				</Box>
				<Button
					variant="outlined"
					disabled={page * pageSize >= totalCount || totalCount === 0}
					onClick={handleNextPage}
				>
					Next
				</Button>
			</Box>

			<Menu
				anchorEl={menuAnchor}
				open={Boolean(menuAnchor)}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleDeleteUser}>
					<Delete sx={{ marginRight: 1 }} />
					Delete User
				</MenuItem>
				<Divider/>
				<MenuItem onClick={()=>setShowForceDelete(true)}>
					<DeleteForever sx={{ marginRight: 1 }} />
					Force Delete User
				</MenuItem>
			</Menu>
			<Dialog open={showForceDelete} onClose={handleCloseForceDelete}>
				<DialogTitle>Confirm Deletion</DialogTitle>
				<DialogContent>
					<Typography gutterBottom>
						All user data for <b>{selectedUser?.email}</b> will be deleted, including PrepKits, Messages, Answer History, and Licenses.
					</Typography>
					<Alert severity={"error"} sx={{my: 2}}>
						<strong>THIS OPERATION IS <u>PERMANENT</u> AND CANNOT BE UNDONE.</strong>
					</Alert>
					<TextField
						label={`Type ${selectedUser?.email} to confirm`}
						variant="outlined"
						fullWidth
						value={typeDelete}
						onChange={(e) => setTypeDelete(e.target.value)}
						margin="dense"
						error={!!typeDelete && !isDeleteConfirmed}
						helperText={!!typeDelete && !isDeleteConfirmed ? `You must type ${selectedUser?.email} exactly to proceed.` : ''}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseForceDelete} color="primary">
						Cancel
					</Button>
					<Button
						onClick={handleForceDeleteUser}
						color="error"
						disabled={!isDeleteConfirmed}
						variant="contained"
					>
						Confirm Force Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Snackbar
				open={!!error}
				onClose={()=>setError(null)}
				autoHideDuration={4000}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert onClose={()=>setError(null)} severity="error" variant="filled">
					{error}
				</Alert>
			</Snackbar>
		</Container>
	)
}

export default Users
