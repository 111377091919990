import React, {useContext, useEffect, useMemo, useRef, useState}                  from "react"
import {useLocation, useNavigate, useParams}                                      from 'react-router-dom'
import {DEBUG, logger}                                                            from "../../util/log-utils"
import {doPut, doStreamPost}                                                      from "../../util/do-fetch"
import {
	Avatar,
	Box, Breadcrumbs,
	Button, ButtonGroup,
	Container,
	Divider,
	Fab,
	Fade,
	IconButton,
	Link,
	Slide,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme
} from "@mui/material"
import "../../stylesheets/PrepKit.css"
import "../../stylesheets/MiniQuestionChat.css"
import Grid                                                                       from "@mui/material/Unstable_Grid2"
import {abcd, createQueuedUserMessage, createStreamingAiMessage,}                 from "../../util/prepkit-utils"
import SendMessage                                                                from "../prepkit/chat/SendMessage.js"
import Messages                                                                   from "../prepkit/chat/Messages.js"
import {useUserStore}                                                                        from "../../state"
import {
	ArrowBack,
	ArrowBackOutlined,
	ArrowForward,
	ArrowForwardOutlined, Check, Close, Home,
	HomeOutlined,
	KeyboardBackspace
} from "@mui/icons-material"
import {useInView}                                                                           from "react-intersection-observer"
import MiniQuestion                                from "./MiniQuestion"
import {MiniPrepKitContext}                        from "./MiniPrepKit"
import {findQuestionInPrepKit, findTopicInPrepKit} from "../../util/mini"
import moment              from "moment"
import getSupabase         from "../../util/supabase-utils"
import LeftRight           from "../LeftRight"
import Score               from "./Score"
import StreamingMarkdown
                           from "../prepkit/chat/StreamingMarkdown"
import {keyframes}         from "@emotion/react"
import MiniQuestionActions from "./MiniQuestionActions"
import MiniQuestionInput   from "./MiniQuestionInput"
import TopicScore          from "./TopicScore"
import TopicTitle          from "./TopicTitle"
import LeftRightCenter     from "../LeftRightCenter"
import {useSwipeable}      from "react-swipeable"
import Tour                from "./Tour"

const log = logger("MiniQuestionView", DEBUG)

const bw = 0

const actions = [
	{
		type: "MEMORIZATION",
		label: "Help me memorize this",
		prompt: "Provide a tool to help me memorize this."
	},
	{
		type: "EXAMPLE",
		label: "Real life example",
		prompt: "Give a real life example."
	},
	{
		type: "INSIGHT",
		label: "Tell me more",
		prompt: "Provide more details."
	},
	{
		type: "HINT",
		label: "Hint, please",
		prompt: "Give me a hint."
	}
]

const fetchMessages = async (prepKit, question) => {
	log.debug("fetchMessages")
	const {data: msgs, error} = await getSupabase()
		.from('message')
		.select('*')
		.eq('prepkit_id', prepKit.id)
		.eq('question_id', question.id)
		.order('created_at', {ascending: true})

	if (error) {
		log.error('Error fetching messages:', error)
		return null
	}
	return msgs
}

function MiniQuestionView() {
	log.trace("Component render")
	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const theme = useTheme()
	const navigate = useNavigate()
	const location = useLocation()
	const {user} = useUserStore()
	const {code} = useParams()
	const prepKit = useContext(MiniPrepKitContext)
	const hash = location.hash

	const [loadedAt, setLoadedAt] = useState()
	const [messages, setMessages] = useState([])
	const [loadedMessagesQuestionId, setLoadedMessagesQuestionId] = useState(null)
	const [isAutoScroll, setIsAutoScroll] = useState(true)
	const [showAnswerHistory, setShowAnswerHistory] = useState(false)
	const [showMessageHistory, setShowMessageHistory] = useState(false)
	const [currentAnswer, setCurrentAnswer] = useState(null)

	const [isPinned, setIsPinned] = useState(false)
	const [showTopic, setShowTopic] = useState(false)
	const [showTopicScore, setShowTopicScore] = useState(false)
	const [topicScoreMessage, setTopicScoreMessage] = useState(null)

	const messagesEndRef = useRef(null)
	const lastScrollTopRef = useRef(0)
	const prevTopicIdRef = useRef()
	const prevQuestionRef = useRef()
	const prevAnswerRef = useRef()

	const {ref: pinRef, entry} = useInView({
		threshold: 0.5, // Trigger when 50% of the element is visible
		triggerOnce: false, // Keep triggering as visibility changes
	})


	const scrollToBottom = (element = messagesEndRef?.current, animate = true) => {
		if (element) {
			element.scrollIntoView({ behavior: animate ? 'smooth' : 'auto' })
		}
	}

	const handleSwipe = (direction) => {
		if (direction === 'left') {
			showTopic
				? handleNext()
				: nextQuestion()
		}
		else if (direction === 'right') {
			showTopic
				? handleBack()
				: previousQuestion()
		}
	}

	const handlers = useSwipeable({
		onSwipedLeft: () => handleSwipe('left'),
		onSwipedRight: () => handleSwipe('right')
	})

	const isBack = useMemo(() => {
		return hash === '#back'
	}, [hash])

	const {topic, question} = useMemo(() => {
		if (prepKit && code) {
			return findQuestionInPrepKit(prepKit, code)
		}
		return {product: null, topic: null, question: null}
	}, [prepKit, code])

	const parentTopics = useMemo(() => {
		if (topic) {
			return topic.path.split('/')
				.filter(p => p)
				.slice(0, -1)
		}
		else {
			return null
		}
	}, [topic])

	const filteredMessages = useMemo(() => {
		if (showMessageHistory) {
			return messages
		}
		else {
			return messages && messages.filter(m => moment(m.created_at)
				.isSameOrAfter(loadedAt || 0))
		}

	}, [loadedAt, messages, showMessageHistory])

	const olderMessages = useMemo(() => {
		return messages && messages.filter(m => moment(m.created_at)
			.isBefore(loadedAt))
	}, [loadedAt, messages])

	const answerToShow = useMemo(() => {
		return currentAnswer ||
			(showAnswerHistory && question && question.userAnswer)
	}, [currentAnswer, showAnswerHistory, question])

	// window scroll listener
	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	// get 'showAnswerHistory' from local storage
	useEffect(() => {
		const storedValue = localStorage.getItem('showAnswerHistory')
		if (storedValue !== null) {
			setShowAnswerHistory(JSON.parse(storedValue))
		}
	}, [])

	// auto scroll to bottom
	useEffect(() => {
		if (isAutoScroll) {
			scrollToBottom()
		}
	}, [messages, isAutoScroll])

	// reset local state when question changes
	useEffect(() => {
		const prevQuestion = prevQuestionRef.current
		const questionChanged =
			(prevQuestion == null && question != null) ||
			(prevQuestion != null && question == null) ||
			(prevQuestion != null &&
				question != null &&
				prevQuestion.id !== question.id)

		if (questionChanged) {
			setCurrentAnswer(null)
			setLoadedAt(moment())
			setIsPinned(false)
		}

		// Update the ref with the current question
		prevQuestionRef.current = question

	}, [question])

	// manage topic refs and optionally stream AI review if topic complete
	useEffect(() => {
		if (!topic) return

		if (prevTopicIdRef.current && prevTopicIdRef.current !== topic.id) {

			const handleReviewTopic = (topicToReview) => {
				const aiMsg = createStreamingAiMessage(true, false)
				aiMsg.stream = true

				setTopicScoreMessage(aiMsg)

				const onData = (data) => {
					setTopicScoreMessage(prev => {
						const clone = {...prev}
						clone.streaming = true
						clone.body += data
						console.log(clone.body)
						return clone
					})
				}

				const onComplete = (data) => {
					setTopicScoreMessage(prev => {
						const clone = {...prev}
						clone.streaming = false
						clone.body = data
						return clone
					})
				}

				const url = `/mini/prepkit/${prepKit.id}/review-topic`
				const options = {
					body: JSON.stringify({topic: topicToReview})
				}
				doStreamPost(url, options, onData, onComplete)
					.catch(err => {
						log.debug("Error sending message")
						log.debug(err)
						throw err
					})
			}

			const prevTopic = findTopicInPrepKit(prepKit, prevTopicIdRef.current)
			const lastQuestionInPrevTopic = prevTopic.questions[prevTopic.questions.length - 1]
			const prevAnsweredQuestion = prevAnswerRef && prevAnswerRef.current && prevAnswerRef.current.question

			// Check if the user just answered the last question of the previous topic
			const justAnsweredLastQuestion = lastQuestionInPrevTopic &&
				prevAnsweredQuestion &&
				prevAnsweredQuestion.id === lastQuestionInPrevTopic.id

			// Check if all questions in the previous topic are answered
			const allQuestionsAnswered = prevTopic.percentAnswered === 1
			/*
			console.log({
				allQuestionsAnswered,
				justAnsweredLastQuestion,
				lastQuestionInPrevTopic: lastQuestionInPrevTopic?.id,
				prevAnsweredQuestion: prevAnsweredQuestion?.id,
				percentAnswered: prevTopic.percentAnswered,
				prevTopic
			})
			 */

			prevAnswerRef.current = null
			if (justAnsweredLastQuestion && allQuestionsAnswered) {
				setShowTopicScore(prevTopic)
				handleReviewTopic(prevTopic)
			}
			else {
				setShowTopicScore(null)
			}

			setShowTopic(true)

		}
		prevTopicIdRef.current = topic.id
	}, [topic, prepKit])

	// update currentAnswer ref
	useEffect(() => {
		if (currentAnswer) {
			prevAnswerRef.current = currentAnswer
		}
	}, [currentAnswer])

	// fetch messages
	useEffect(() => {
		const fetchAndSetMessages = async () => {
			if (!prepKit || !question || (question.id === loadedMessagesQuestionId)) return

			setLoadedMessagesQuestionId(question.id)
			const fetchedMessages = await fetchMessages(prepKit, question)

			setMessages(prevMessages => {
				if (prevMessages.length > 0 && prevMessages[0].questio_id === question.id) {
					return [...prevMessages, ...fetchedMessages]
						.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
				}
				return fetchedMessages || []
			})

			setTimeout(() => scrollToBottom(messagesEndRef && messagesEndRef.current), 300)
		}

		log.debug(`useEffect[question:${question?.id}]`)

		if (question && showMessageHistory) {
			fetchAndSetMessages()
		}
	}, [prepKit, question, showMessageHistory, loadedMessagesQuestionId, messagesEndRef])

	const nextQuestion = async () => {
		navigate(`/prepkit/${prepKit.id}/q/${question.next}#next`)
	}

	const previousQuestion = async () => {
		navigate(`/prepkit/${prepKit.id}/q/${question.previous}#back`)
	}

	const saveAnswer = async (answer) => {
		const url = `/mini/prepkit/${prepKit.id}/question/${question.id}/answer/${answer}`
		await doPut(url)
	}

	const handleScroll = () => {
		const scrollTop = window.scrollY
		const scrollHeight = document.documentElement.scrollHeight
		const clientHeight = window.innerHeight

		// Determine scroll direction
		const scrollDirection = scrollTop > lastScrollTopRef.current ? 'down' : 'up'
		lastScrollTopRef.current = scrollTop

		// If the user scrolls up, disable auto-scroll
		if (scrollDirection === 'up') {
			setIsAutoScroll(false)
		}

		// Re-enable auto-scroll if the user is at the bottom of the page
		if (scrollHeight - scrollTop === clientHeight) {
			setIsAutoScroll(true)
		}
	}

	const handleHome = () => {
		navigate(`/prepkit/${prepKit.id}`)
	}
	
	const handleBack = () => {
		if (showTopicScore) {
			setShowTopicScore(false)
		}
		else {
			if (showTopic) {
				setShowTopic(false)
				if (!isBack) previousQuestion()
			}
			else {
				previousQuestion()
			}
		}
	}

	const handleNext = () => {
		if (showTopicScore) {
			setShowTopicScore(false)
		}
		else {
			if (showTopic) {
				setShowTopic(false)
				if (isBack) nextQuestion()
			}
			else {
				nextQuestion()
			}
		}
	}

	const handleSend = async (message, answerClicked) => {
		setShowTopic(null)
		const msgs = [...messages]

		if (!message || message.trim().length === 0) {
			return
		}

		let userMsg = createQueuedUserMessage(message, true, false)
		msgs.push(userMsg)

		const aiMsg = createStreamingAiMessage(true, false)
		aiMsg.streaming = true

		// add aiMsg to local state but NOT to array sent to server
		log.debug("setMessages:: adding aiMsg")
		setMessages([...msgs, aiMsg])

		setTimeout(() => {
			setIsAutoScroll(true)
			scrollToBottom()
		}, 100)

		const onData = (data) => {
			log.debug("setMessages:: streaming onData")
			setMessages(prev => {
				const index = prev.findIndex(m => aiMsg.id === m.id)
				if (index !== -1) {
					// Found message to update with streaming data
					const updatedMessages = [...prev]
					const msg = updatedMessages[index]//cloneDeep(updatedMessages[index])
					msg.streaming = true
					msg.body += data // Update body
					return updatedMessages
				}
				else {
					return prev  // No matching message found, so no change
				}
			})
		}

		const onComplete = (data) => {
			// do we need to do anything?
			// todo: you would think we should set aiMsg.stream = false and update messages state, but at
			// this point the server is about to insert the message into the database we will receive
			// the INSERT event on message, so let's just let that happen which will cause it to update
			// messages state anyway
			// ... so, no, don't do anything
			log.debug("streaming onComplete, calling setMessages " + data)

			log.debug("setMessages:: streaming onComplete")
			setMessages(prev => {
				const index = prev.findIndex(m => aiMsg.id === m.id)
				if (index !== -1) {
					log.debug("setMessages:: Found message to update with streaming data")
					const updatedMessages = [...prev]
					const msg = updatedMessages[index]
					msg.body = data
					msg.streaming = false
					return updatedMessages // Replace the old messages array
				}
				else {
					log.debug("setMessages:: No matching message found, so no change")
					return prev  // No matching message found, so no change
				}
			})
		}

		const sendUrl = `/mini/prepkit/${prepKit.id}/question/${question.id}/send?saveAnswer=${answerClicked !== true}`
		doStreamPost(sendUrl, {body: JSON.stringify(msgs)}, onData, onComplete)
			.catch(err => {
				log.debug("Error sending message")
				log.debug(err)
				throw err
			})
	}

	const handleAnswer = async (q, a, label) => {
		setCurrentAnswer({
			question: q,
			answer: a,
			is_correct: question.answer === a,
			created_at: new Date()
		})
		handleSend(`${abcd(a)}. ${label}`, true)
		await saveAnswer(a)
	}

	// Update the question pinned state based on the middle point of the element
	if (entry) {
		let triggerY = entry.boundingClientRect.top + entry.boundingClientRect.height / 2
		if (triggerY <= 0 && !isPinned) {
			setIsPinned(true)
		}
		else if (triggerY > 0 && isPinned) {
			setIsPinned(false)
		}
	}

	if (!question) return null


	const continueButtons = <Fade in={true}>
			<Box mt={3}
			     display={"flex"}
			     flex={1}
			     width={"100%"}
			     alignItems={"center"}
			     justifyContent={"center"}
			     border={`${bw}px solid red`}
			>
				<ButtonGroup
					variant={"outlined"}
				>
					<Button onClick={handleBack}
					        size={"large"}
					        sx={{
								borderRadius: 5,
						        borderColor: theme.palette.divider,
						        color: theme.palette.text.primary
							}}
					>
						<ArrowBack sx={{color: theme.palette.text.primary}}/>
						{ !isSmallScreen && "Back" }
					</Button>
					<Button onClick={()=>navigate(`/prepkit/${prepKit.id}`)}
					        size={"large"}
					        sx={{
								borderRadius: 5,
						        borderColor: theme.palette.divider,
						        color: theme.palette.text.primary
							}}
					>
						Overview
					</Button>
					<Button onClick={handleNext}
					        size={"large"}
					        sx={{
								borderRadius: 5,
						        borderColor: theme.palette.divider,
						        color: theme.palette.text.primary
							}}
					>
						{ !isSmallScreen && "Continue" }
						<ArrowForward sx={{color: theme.palette.text.primary}}/>
					</Button>
				</ButtonGroup>
			</Box>
		</Fade>


	return (
		<Box
			{...handlers}
			sx={{ border: `0px solid blue`}}
			display={"flex"}
			flexDirection={"column"}
		>
			{
				isSmallScreen && <Divider />
			}
			<Breadcrumbs separator={""}
			             onClick={() => navigate(`/prepkit/${prepKit && prepKit.id}`)}
			             sx={{
							 m: 2,
				             mt: 1,
				             mb: isSmallScreen ? 1 : 2
						 }}
			>
				{/*
					parentTopics && parentTopics.map((p, index) => (
						<Link
							key={index}
							underline="hover"
							variant={"body2"}
							color="inherit"
							sx={{
								display: 'flex',
								alignItems: 'center',
								cursor: 'pointer'
							}}
						>
							{
								index === 0 &&
								!isSmallScreen &&
								<HomeOutlined sx={{ mr: 0.5, fontSize: "18px"}} />
							}
							{p}
						</Link>
					))
				*/}

				<Typography variant={"body2"} color={"secondary"}>
					{topic.name}
				</Typography>
			</Breadcrumbs>


			<Container style={{
					padding: 0,
					maxWidth: isSmallScreen ? 'auto' : '800px',
					width: 'auto',
					border: `${bw}px solid yellow`
				}}
			>
				<Grid sm={12}
				      ref={pinRef}
				      sx={{border: `${bw}px solid red`}}
				>
					<Grid sm={12}
					      ref={pinRef}
					      sx={{
							  border: `${bw}px solid magenta`
						  }}
					>
						<MiniQuestion prepKit={prepKit}
						              answerToShow={answerToShow}
						              question={question}
						              topic={topic}
						              parentTopics={parentTopics}
						              onSend={handleSend}
						              onAnswer={handleAnswer}
						/>
						{ /*
							<LeftRight
								left={

									question &&
									question.userAnswer &&
									!currentAnswer &&
									<Box sx={{
										fontSize: "13px",
										p: 1,
										cursor: 'pointer'
									}}
									     onClick={() => setShowAnswerHistory(!showAnswerHistory)}
									>
										{
											showAnswerHistory
												? <Link sx={{cursor: 'pointer', textDecoration: 'none'}}
												        onClick={() => setShowAnswerHistory(!showAnswerHistory)}
												>
													Hide Previous Answer
												</Link>
												: <Tooltip
													title={`Click to show previous answer`}
													enterDelay={500}
												>
													<span>
														Show Previous Answer
														{
															question.userAnswer.is_correct
																? <span style={{
																	cursor: 'pointer',
																	color: theme.palette.success.main,
																	textDecoration: 'underline',
																	marginLeft: '3px'
																}}>
															Correct
														  </span>
																: <span style={{
																	color: theme.palette.error.main,
																	textDecoration: 'underline',
																	marginLeft: '3px'
																}}>
															Incorrect
														  </span>
														}
													</span>
												</Tooltip>

										}
									</Box>
								}
								right={
									(
										question.hasMessages ||
										(olderMessages && olderMessages.length > 0)
									) &&
									<Box sx={{fontSize: "13px", p: 1}}>
										<Link sx={{cursor: 'pointer', textDecoration: 'none'}}
										      onClick={() => setShowMessageHistory(!showMessageHistory)}
										>
											{showMessageHistory ? 'Hide ' : 'Show '} Older Messages
										</Link>
									</Box>
								}
							/>

						*/}

						<Box sx={{px: 1, py: 1}}>
						 <LeftRight
							 left={
								 <Typography sx={{opacity: 0.4, px: 1, fontSize: "13px"}}>
									 {question.number}&nbsp;of&nbsp;{topic.questions.length}
								 </Typography>
							 }
							 right={
								(
									(question.userAnswer && !currentAnswer)
									||
									(question.hasMessages || (olderMessages && olderMessages.length > 0))
								) &&
									<Tooltip enterDelay={250}
									         placement={"top"}
									         title={`${showAnswerHistory && showMessageHistory ? 'Hide ' : 'Show '} previous answer and message history.`}
									>
										<Link sx={{fontSize: "13px", cursor: 'pointer', textDecoration: 'none'}}
										      onClick={() => {
												  const val = showAnswerHistory && showMessageHistory
												  setShowMessageHistory(!val)
											      setShowAnswerHistory(!val)
											  }}
										>
											{showAnswerHistory && showMessageHistory ? 'Hide ' : 'Show '} History
										</Link>
									</Tooltip>
							}
						/>
						</Box>

					</Grid>
					{
						isPinned &&
						<Box
							sx={{
								position: 'fixed',
								top: 0,
								left: 0,
								right: 0,
								zIndex: 1300,
								border: `${bw}px solid magenta`,
								opacity: showTopic ? 0 : 1,
								borderBottom: isSmallScreen && `1px solid ${theme.palette.divider}`,
								boxShadow: isSmallScreen && '0px 3px 10px rgba(0, 0, 0, 0.2)'

							}}
						>
							<Box
								sx={{
									width: isSmallScreen ? 'auto' : '950px',
									margin: '0 auto',
									px: isSmallScreen ? 0 : 9,
									transition: 'background 250ms',
									backgroundColor: theme.palette.transparent,
									xxbackdropFilter: "blur(10px)",
									xxWebkitBackdropFilter: "blur(10px)",
									border: `${bw}px solid magenta`
								}}
							>
								<MiniQuestion prepKit={prepKit}
								              answerToShow={answerToShow}
								              question={question}
								              topic={topic}
								              parentTopics={parentTopics}
								              collapsible={true}
								              isPinned={isPinned}
								              onSend={handleSend}
								              onAnswer={handleAnswer}
								/>
							</Box>
						</Box>
					}
				</Grid>
				<Grid sm={12} sx={{
					mt: 1,
					pb: '70px',
					mr: isSmallScreen ? 1 : 0,
					border: `0px solid red`,
				}}>
					{
					//<pre>{JSON.stringify({hasMessages: question.hasMessages})}</pre>
					//<pre>{JSON.stringify({messages: messages && messages.length})}</pre>
					//<pre>{JSON.stringify({currentAnswer})}</pre>
					//<pre>{JSON.stringify({prevAnswerRef: prevAnswerRef.current})}</pre>
					//<pre>{JSON.stringify({topic: topic.percentAnswered})}</pre>
					//<pre>{JSON.stringify({showAnswerHistory})}</pre>
					//<pre>{JSON.stringify({showMessageHistory})}</pre>
					//<pre>{JSON.stringify({olderMessages: olderMessages.length})}</pre>
					//<pre>{JSON.stringify({loadedAt})}</pre>
					}

					{
						!showTopic &&
						<Messages
							contextId={user.id}
							messages={filteredMessages}
							showMessagesAfter={0}
						/>
					}
					{
						filteredMessages &&
						filteredMessages.length === 0 &&
						!currentAnswer &&
						!isSmallScreen &&
						<Box display="flex"
						     pt={isSmallScreen ? 0 : 10}
						     flexDirection={isSmallScreen ? "column" : "row"}
						     justifyContent={isSmallScreen ? "flex-start" : "center"}
						     alignItems={isSmallScreen ? "center" : "flex-start"}
						>
							<Box id={"tour-actions"}>
							{
								actions.map(a => {
									return <Button
										key={a.label}
										label={a.label}
										color="secondary"
										onClick={() => handleSend(a.prompt || a.label)}
										sx={{
											cursor: "pointer",
											marginLeft: '5px',
											marginRight: '5px',
											fontSize: '.9em',
											maxWidth: isSmallScreen ? "250px" : "auto",
											marginTop: isSmallScreen ? "15px" : 0,
											textTransform: 'none',
											color: theme.palette.text.disabled,
											'&:hover': {
												color: theme.palette.secondary.main
											}
										}}
									>
										{a.label}
									</Button>
								})
							}
							</Box>
						</Box>
					}
					{
						// currentAnswer &&
						// <Box display="flex" justifyContent="center">
						// 	<Chip
						// 		label="Next"
						// 		color="info"
						// 		variant="outlined"
						// 		onClick={nextQuestion}
						// 		onDelete={nextQuestion}
						// 		deleteIcon={<ArrowForward/>}
						// 		sx={{
						// 			cursor: "pointer",
						// 			marginLeft: '5px',
						// 			marginRight: '5px',
						// 			padding: '0',
						// 			fontSize: '.9em',
						// 			'&.MuiChip-root': {
						// 				xpadding: '26px',
						// 				xborderRadius: '26px'
						// 			}
						// 		}}
						// 	/>
						// </Box>
					}
				</Grid>
			</Container>

			{/* mini question input */}
			{
				!showTopic && 
				!showTopicScore &&
				<MiniQuestionInput
					actions={actions}
					onSend={handleSend}
					messageInputDisabled={showTopic}
					currentAnswer={currentAnswer}
					onHome={handleHome}
					onBack={handleBack}
					onNext={handleNext}
				/>
			}
			<div style={{marginBottom: "20px", border: "0px solid red"}} ref={messagesEndRef}/>

			{
				showTopic &&
				<Box
					sx={{
						position: 'absolute',
						top: "70px",
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: theme.palette.background.paper,
						border: `${bw}px solid pink`,
						zIndex: 1100,
						pb: "70px",
						display: 'flex',              // Add this
						flexDirection: 'column',      // Add this
						justifyContent: 'flex-start',     // Add this if you want vertical centering
					}}
				>
					{
						showTopicScore &&
						<TopicScore
							topic={showTopicScore}
							aiMessage={topicScoreMessage}
							continueButtons={continueButtons}
						/>
					}
					{
						showTopic &&
						!showTopicScore &&
						<TopicTitle
							topic={topic}
							parentTopics={parentTopics}
							continueButtons={continueButtons}
						/>
					}
				</Box>
			}
			<Tour token={"question"} last={"Let's Go!"}/>
		</Box>
	)
}

export default React.memo(MiniQuestionView)