import {Helmet} from "react-helmet-async"
import {Outlet} from "react-router-dom"

const AdminLayout = ({ children }) => (
	<>
		<Helmet>
			<title>Admin - Birdsy</title>
			<meta name="description" content="Admin - Birdsy.ai" />
		</Helmet>
		<div className="admin-layout">
			<Outlet />
		</div>
	</>
)

export default AdminLayout
