import React, {useEffect, useMemo, useState} from 'react'
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Container,
	DialogContent,
	Divider,
	Link,
	List,
	ListItemButton,
	Skeleton,
	Typography,
	useTheme
}                                            from "@mui/material"
import BirdsyMiniLogo                        from "../BirdsyMiniLogo"
import {doPost}                  from "../../util/do-fetch"
import PackageListItem           from "./PackageListItem"
import {fmt2Dec}                 from "../../util/prepkit-utils"
import LeftRight                 from "../LeftRight"
import Grid                      from "@mui/material/Unstable_Grid2"
import MiniCheckout              from "./MiniCheckout"
import {Elements}                from "@stripe/react-stripe-js"
import {loadStripe}              from "@stripe/stripe-js"
import {useNavigate}             from "react-router-dom"
import DialogWithBlurredBackdrop from "../DialogBlurred"
import SelectPackageList         from "./SelectPackageList"
import useMediaQuery             from "@mui/material/useMediaQuery"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let _stripePromise = null
const stripePromise = () => {
	if (!_stripePromise) {
		_stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
	}
	return _stripePromise
}

const PurchasePackage = ({title, prepKit, packages,
	                         selectedPackage, selectedOption,
							 onSelectPackage, onSelectOption,
	                         onCancel, onPurchaseComplete}) => {

	const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))
	const navigate = useNavigate()
	const [clientSecret, setClientSecret] = useState(null)
	const [purchaseComplete, setPurchaseComplete] = useState(false)

	useEffect(() => {
		if(selectedPackage && selectedOption) {
			const order = {
				packageId: selectedPackage.id,
				optionId: selectedOption.id
			}
			const options = {body: JSON.stringify(order)}
			doPost(`/mini/create-payment-intent`, options)
				.then(res => res.json())
				.then(data => setClientSecret(data.clientSecret))
		}
		else {
			setClientSecret(null)
		}
	}, [selectedPackage, selectedOption])

	useEffect(() => {
		if (packages && packages.length === 1 && selectedPackage) {
			const pkg = packages[0]
			if(selectedPackage.id !== pkg.id) {
				onSelectPackage(packages[0])
			}
		}
	}, [packages, selectedPackage])

	const handlePurchaseComplete = (paymentIntent) => {
		setPurchaseComplete(true)
		onPurchaseComplete(paymentIntent)
	}

	let dialogContent = null
	let dialogWidth = 500

	if(purchaseComplete) {
		dialogContent = <Box>
			<Alert severity="success" sx={{m: 2}}>
				Payment succeeded! Thank you for your purchase.
			</Alert>
			<Box py={2} textAlign="center">
				<Button
					variant="outlined"
					size="large"
					onClick={()=> navigate('/prepkit')}
				>
					Continue
				</Button>
			</Box>
		</Box>
	}
	else if (selectedPackage && selectedOption) {
		dialogContent = <Elements stripe={stripePromise()}>
			<Box>
				<Box pt={2} pl={2} pb={1}>
					<Typography variant="h6">Complete Purchase</Typography>
				</Box>

				<MiniCheckout clientSecret={clientSecret}
				              selectedPackage={selectedPackage}
				              selectedOption={selectedOption}
				              onPurchaseComplete={handlePurchaseComplete}
				/>
				<Divider/>
				{
					<Box mt={2}>
						<Link sx={{cursor: 'pointer'}}
						      onClick={() => {
							      onSelectOption(selectedPackage, null)
						      }}>
							&larr; Back
						</Link>
					</Box>
				}
			</Box>
		</Elements>
	}
	else if (selectedPackage) {
		dialogWidth = 800
		dialogContent = <Box>
			<SelectOptionList selectedPackage={selectedPackage}
			                  onSelectOption={onSelectOption}/>
			{
				packages &&
				packages.length > 1
					? <Box mt={2} ml={1}>
						<Link sx={{cursor: 'pointer'}}
						      onClick={() => {
							      onSelectOption(null, null)
						      }}>
							&larr; Back
						</Link>
					  </Box>
					: <Box mt={2} ml={1}>
						<Link sx={{cursor: 'pointer'}}
						      onClick={() => {
							      onCancel && onCancel()
						      }}>
							Close
						</Link>
					  </Box>
			}
		</Box>
	}
	else {
		dialogContent = <Box>
			<Box p={2}>
				<Typography variant="h6">{title}</Typography>
				<Typography variant="p">Choose one to continue</Typography>
			</Box>
			<SelectPackageList relatedPackages={packages}
			                   onSelectPackage={onSelectPackage}
			/>
			{
				onCancel &&
				<Box mt={2}>
					<Link sx={{cursor: 'pointer'}}
					      onClick={onCancel}>
						Close
					</Link>
				</Box>
			}
		</Box>
	}

	return (
		<Container style={{maxWidth: '800px', textAlign: 'center'}}>
			<DialogWithBlurredBackdrop
				open={true}
				maxWidth={"md"}
				fullScreen={isSmallScreen}
			>
				<DialogContent
					sx={{
						width: isSmallScreen ? "auto" : dialogWidth,
						transition: 'width 500ms, height 500ms',
						p: isSmallScreen ? 1 : 4
					}}
				>
					<Box display="flex" justifyContent="center" pl={3}>
						<BirdsyMiniLogo width={125}/>
					</Box>
					{dialogContent}
				</DialogContent>
			</DialogWithBlurredBackdrop>
		</Container>
	)
}

const SelectOptionList = ({selectedPackage, onSelectOption}) => {
	const theme = useTheme()
	const [showMore, setShowMore] = useState(false)
	const hasMore = useMemo(()=>{
		return selectedPackage.description && selectedPackage.description.length > 0
	}, [selectedPackage])

	return <Box>
		<Box p={2}>
			<Typography variant="h6" pl={1}>
				{selectedPackage.name}
			</Typography>
			<Typography variant="body2" pl={1}>
				{selectedPackage.tagline}
				{
					hasMore &&
					<Link sx={{cursor: 'pointer', ml: 1}}
					      onClick={()=>setShowMore(!showMore)}
					>
						{showMore ? 'Hide Details' : 'Details'}
					</Link>
				}
			</Typography>

			{
				showMore &&
				<Card
					elevation={0}
					sx={{
						width: '100%',
						cursor: 'pointer',
						transition: 'backgroundColor 500ms',
						backgroundColor: theme.palette.background.default,
						mt: 2
					}}
				>
					<CardContent>
						<b>Includes: </b>
						<ul>
							{
								selectedPackage.products.map(p => <li key={`product-${p.id}`}>- {p.name}</li>)
							}
						</ul>
						<br/>
						<b>Details: </b>
						<Typography>{ selectedPackage.description }</Typography>
					</CardContent>
				</Card>
			}
		</Box>
		<Grid container>
			{
				selectedPackage &&
				selectedPackage.options.map(opt => (
						<Grid xs={12} sm={4}
						      key={`option-${opt.id}`}
						      sx={{p: 1}}
						>
							<Card
								sx={{
									width: '100%',
									textAlign: "center",
									cursor: "pointer",
									'&:hover': {
										backgroundColor: theme.palette.action.hover,
									}
								}}
								onClick={() => onSelectOption(selectedPackage, opt)}
							>
								<CardContent sx={{height: '90px'}}>
									<Typography variant="h5">
										{opt.name}!
									</Typography>
									<Typography variant="h6">
										${fmt2Dec(opt.price_in_cents / 100)}
									</Typography>
								</CardContent>
								<Box justifyContent="center" alignItems="center">
									<Button sx={{my: 1}}>
										Choose Plan
									</Button>
								</Box>
							</Card>
						</Grid>
					)
				)
			}
		</Grid>
	</Box>


	return <List sx={{border: `1px solid ${theme.palette.divider}`, p: 0}}>
		<PackageListItem
			key={`selected-${selectedPackage.id}`}
			packageData={selectedPackage}/>
		{
			selectedPackage &&
			selectedPackage.options.map(opt => (
					<ListItemButton
						key={`option-${opt.id}`}
						onClick={() => onSelectOption(selectedPackage, opt)}
					>
						<LeftRight
							left={<Typography><b>{opt.name}</b></Typography>}
							right={<Typography variant="h6">${fmt2Dec(opt.price)}</Typography>}
						/>

					</ListItemButton>
				)
			)

		}
	</List>
}

export default PurchasePackage
