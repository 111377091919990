import React, { useState, useEffect }                                                        from 'react'
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	Divider,
	Paper,
	Chip,
	MenuItem,
	Select,
	DialogTitle,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	List,
	ListItemButton,
	ListItem,
	useTheme,
	Alert, AlertTitle, Stack, FormControlLabel, Switch
} from '@mui/material'
import { v4 as uuidv4 }                                                              from 'uuid'
import MDEditor                                                                      from '@uiw/react-md-editor'
import ReactMarkdown                                                                 from 'react-markdown'
import {
	AddCircle,
	ArrowBack,
	Circle,
	CircleOutlined,
	Close,
	RemoveCircle,
	RemoveCircleOutline
}                                                                  from "@mui/icons-material"
import LeftRight                                                   from "../LeftRight"
import {doGet, doPost}                                             from "../../util/do-fetch"
import Grid                                                        from "@mui/material/Unstable_Grid2"
import {useNavigate, useParams}                                    from "react-router-dom"
import {STATUS_ARCHIVED, STATUS_DRAFT, STATUS_PUBLISHED, STATUSES} from "../../util/package-statuses"
import {fmt2Dec}                                                   from "../../util/prepkit-utils"
import SelectCategory                                              from "./SelectCategory"
import getSupabase                                                 from "../../util/supabase-utils"

const newPackage = () => {
	return {
		name: '',
		token: '',
		tagline: '',
		excerpt: '',
		description: '',
		products: [],
		options: [],
		archived_at: null,
		published_at: null
	}
}

const Package = ({ packageToEdit }) => {
	const navigate = useNavigate()
	const theme = useTheme()
	const {id} = useParams()
	const [packageData, setPackageData] = useState(newPackage())
	const [status, setStatus] = useState(null)
	const [targetStatus, setTargetStatus] = useState('')
	const [editField, setEditField] = useState(null)
	const [products, setProducts] = useState(null)
	const [showProducts, setShowProducts] = useState(false)
	const [loading, setLoading] = useState(true)
	const [saving, setSaving] = useState(false)
	const [errors, setErrors] = useState(null)
	const [success, setSuccess] = useState(null)

	useEffect(()=>{
		fetchProducts()
	}, [])


	useEffect(()=>{
		if(id) {
			fetchPackage()
		}
		else {
			setLoading(false)
		}
	}, [id])

	useEffect(() => {
		setStatus(prev => {
			return packageData.archived_at
				? STATUS_ARCHIVED
				: packageData.published_at
					? STATUS_PUBLISHED
					: STATUS_DRAFT
		})
	}, [packageData])

	useEffect(()=>{
		if(status) {
			setTargetStatus(status && status.label)
		}
	}, [status])

	const fetchProducts = async () => {
		try {
			const response = await doGet(`/admin/products`)
			const data = await response.json()
			setProducts(data)
		}
		catch (err) {
			console.error(err)
		}
	}

	const fetchPackage = async () => {
		try {
			const response = await doGet(`/admin/packages/${id}`)
			const data = await response.json()
			setPackageData(data)
			setLoading(false)
		}
		catch (err) {
			console.error(err)
		}
	}

	const savePackage = async (packagetoSave) => {
		setErrors(null)
		try {
			const created = Boolean(packagetoSave.id)
			const fetchOpts = {body: JSON.stringify(packagetoSave)}
			console.log({packagetoSave})
			const response = await doPost(`/admin/packages`, fetchOpts)
			if(response.ok) {
				const data = await response.json()
				setPackageData(data)
				setSuccess(`Package ${created ? 'created' : 'updated'} successfully!`)
			}
			else if (response.status === 400) {
				const err = await response.json()
				setErrors(err.errors)
			}
			else {
				setErrors(["Unrecognized response from server."])
			}
		}
		catch (err) {
			console.error(err)
		}
	}

	const generateTokenFromPackageName = (onlyIfEmpty) => {
		let packageToken = packageData.token
		if (onlyIfEmpty && packageToken && packageToken.length > 0) return
		packageToken = packageData.name.trim()
			.toLowerCase()
			.replace(/[^a-z0-9]+/g, '-')
		setPackageData({ ...packageData, token: packageToken })
	}

	const isProductSelected = (product) => {
		return packageData &&
			packageData.products &&
			packageData.products.some(p => p.id === product.id)
	}

	const handleChange = (e) => {
		const { name, value } = e.target
		setPackageData({ ...packageData, [name]: value })
	}
	const handleBlurName = (e) => {
		generateTokenFromPackageName(true)
	}

	const handleProductChange = (index, value) => {
		const updatedProducts = [...packageData.products]
		updatedProducts[index] = value
		setPackageData({ ...packageData, products: updatedProducts })
	}

	const handlePricingChange = (index, field, value) => {
		const updatedPricing = [...packageData.options]
		updatedPricing[index][field] = value
		setPackageData({ ...packageData, options: updatedPricing })
	}

	const handleAddProduct = (product) => {
		setPackageData({ ...packageData, products: [...packageData.products, product] })
	}

	const handleRemoveProduct = (product) => {
		setPackageData({ ...packageData, products: packageData.products.filter(p => p.id !== product.id) || []})
	}

	const handleAddPricingOption = () => {
		setPackageData({ ...packageData, options: [...packageData.options, { id: uuidv4(), name: '', price_in_cents: '', license_days: '' }] })
	}

	const handleRemovePricingOption = (index) => {
		setPackageData({ ...packageData, options: packageData.options.filter((p,i) => i !== index) || []})
	}

	const handleSave = async () => {
		setSaving(true)
		setErrors(null)
		try {
			const packageToSave = {...packageData}
			if(status.label !== targetStatus) {
				if(targetStatus === STATUS_DRAFT.label) {
					packageToSave.published_at = null
					packageToSave.archived_at = null
				}
				else if(targetStatus === STATUS_PUBLISHED.label) {
					packageToSave.published_at = new Date()
					packageToSave.archived_at = null
				}
				else if(targetStatus === STATUS_ARCHIVED.label) {
					packageToSave.published_at = null
					packageToSave.archived_at = new Date()
				}
			}
			await savePackage(packageToSave)
		}
		catch(e) {
			setErrors(["Error saving package: " + e])
		}
		finally {
			setSaving(false)
		}
	}

	const handleEditField = (field) => {
		setEditField(field)
	}

	const handleDoneEditing = () => {
		setEditField(null)
	}

	if(loading) {
		return <Typography color="text.disabled" textAlign="center">Loading...</Typography>
	}

	return (
		<Grid container p={3} >
			<Grid xs={12}>
				{
					success &&
					<Alert color="success"
					       sx={{mb: 2}}
					       onClose={()=>setErrors(null)}
					       action={
						       <Button color="inherit" size="small" onClick={()=>navigate(`/admin/packages`)}>
							       View Packages
						       </Button>
					       }
					>
						<AlertTitle>Success</AlertTitle>
						{success}

					</Alert>
				}
				{
					errors && errors.length > 0 &&
					<Alert color="error" sx={{mb: 2}} onClose={()=>setErrors(null)}>
						<AlertTitle>Please correct the following errors</AlertTitle>
						{errors.map((e,i) => <Box key={`error-${i}`}>&middot; {e}</Box>)}
					</Alert>
				}
				<LeftRight
					left={
						<Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
							<IconButton onClick={() => navigate(`/admin/packages`)}>
								<ArrowBack/>
							</IconButton>
							<Typography variant={"h5"}>
								{packageData.id ? 'EDIT PACKAGE' : 'CREATE PACKAGE'}
							</Typography>
							{
								status && <Chip sx={{ml: 2}} size={"small"} variant={"outlined"} color={status.color} label={status.label}/>
							}
						</Box>
					}
					right={
						<LeftRight spacing={1}
							left={
								<Select
									value={targetStatus}
									size="small"
									onChange={e => setTargetStatus(e.target.value)}
									displayEmpty
									fullWidth
									placeholder="Status"
									sx={{mr: 1}}
								>
									<MenuItem value={''}>
										<Typography component="span" style={{opacity: 0.5}}>
											Select Status
										</Typography>
									</MenuItem>
									{
										STATUSES.map((s, i) => {
											return <MenuItem key={s.label} value={s.label}>
												<Box display="flex" flexDirection="row" justifyContent="flex-start"
												     alignItems="center">
													<Circle fontSize='14px' color={s.color} sx={{mr: 1}}/>
													<Typography component="span">
														{s.label}
													</Typography>
												</Box>
											</MenuItem>
										})
									}
								</Select>
							}
							right={
								<Button size="large" variant="contained" color="primary" onClick={handleSave}>
									Save Package
								</Button>
							}
						/>
					}
				/>
				<Divider sx={{my: 2}}/>
			</Grid>
			<Grid xs={7}>
				<Box m={2}>
					<Typography variant="h6" sx={{pb: 2}}>Package Info</Typography>

					<Grid container spacing={3}>
						<Grid xs={12}>
							<TextField fullWidth label="Name" name="name" value={packageData.name}
							           onChange={handleChange}
							           onBlur={handleBlurName}
							/>
						</Grid>

						<Grid xs={12}>
							<SelectCategory category={packageData.category_id}
							                onChangeCategory={(c)=>setPackageData({ ...packageData, category_id: c.id })}
							/>
						</Grid>

						<Grid xs={12}>
							<TextField fullWidth label="URL Token" name="token" value={packageData.token} onChange={handleChange} />
						</Grid>

						<Grid xs={12}>
							<TextField fullWidth label="Tagline" name="tagline" value={packageData.tagline} onChange={handleChange} />
						</Grid>

						<Grid xs={12}>
							<Box mb={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
								<Typography variant="h6">Excerpt</Typography>
								{
									editField==='excerpt'
									? <Button variant="outlined" color="primary" onClick={handleDoneEditing}>
										Done
									  </Button>
									: <Button variant="outlined" color="primary" onClick={() => handleEditField('excerpt')}>
										Edit
									  </Button>
								}
							</Box>
							{editField === 'excerpt' ? (
								<MDEditor
									value={packageData.excerpt}
									onChange={(value) => setPackageData({ ...packageData, excerpt: value })}
								/>
							) : (
								<Box>
									<Paper onClick={() => handleEditField('excerpt')}
									       style={{
											   padding: '10px',
										       cursor: 'pointer'
										   }}>
										{
											packageData.excerpt && packageData.excerpt.length > 0
											?<ReactMarkdown>{packageData.excerpt}</ReactMarkdown>
											:<Typography sx={{p: 0, m: 0}} color="text.disabled">
												+ Add Excerpt
											</Typography>
										}

									</Paper>
								</Box>
							)}
						</Grid>
						<Grid xs={12}>
							<Box mb={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
								<Typography variant="h6">Description</Typography>
								{
									editField==='description'
										? <Button variant="outlined" color="primary" onClick={handleDoneEditing}>
											Done
										</Button>
										: <Button variant="outlined" color="primary" onClick={() => handleEditField('description')}>
											Edit
										</Button>
								}
							</Box>
							{editField === 'description' ? (
								<MDEditor
									value={packageData.description}
									onChange={(value) => setPackageData({ ...packageData, description: value })}
								/>
							) : (
								<Box>
									<Paper onClick={() => handleEditField('description')}
									       style={{
										       padding: '10px',
										       cursor: 'pointer'
									       }}>
										{
											packageData.description && packageData.description.length > 0
												?<ReactMarkdown>{packageData.description}</ReactMarkdown>
												:<Typography sx={{p: 0, m: 0}} color="text.disabled">
													+ Add Description
												</Typography>
										}

									</Paper>
								</Box>
							)}
						</Grid>

					</Grid>
				</Box>
			</Grid>
			<Grid xs={5}>
				<Box mx={2} mt={1}>
					<LeftRight
						sx={{mb: 1}}
						left={<Typography variant="h6">Products Included in Package</Typography>}
						right={<Button sx={{mt: 1}}
						               variant="outlined"
						               color="primary"
						               onClick={()=>setShowProducts(true)}>
							Add Products
						</Button>}
					/>
					<List sx={{border: "1px solid " + theme.palette.divider, borderRadius: "5px"}}>
						{
							(!packageData.products || packageData.products.length === 0) &&
							<Typography sx={{my: 1, ml: 2, cursor: "pointer"}}
							            color="text.disabled"
							            onClick={()=>setShowProducts(true)}
							>
								+ Add Product
							</Typography>
						}

						{packageData.products.map((product, index) => (
							<ListItem key={`product-${index}`} secondaryAction={
								<IconButton edge="end" onClick={()=>handleRemoveProduct(product)} >
									<RemoveCircleOutline />
								</IconButton>
							}>
								<Typography variant="b">{product.name}</Typography>
							</ListItem>
						))}
					</List>
				</Box>
				<Box mx={2} mt={2}>
					<LeftRight
						sx={{mb: 1}}
						left={<Typography variant="h6">License + Pricing Options</Typography>}
						right={<Button sx={{mt: 1}} variant="outlined" color="primary" onClick={handleAddPricingOption}>
							Add Option
						</Button>}
					/>
					{
						(!packageData.options || packageData.options.length === 0) &&
						<Typography sx={{py: 2, pl: 2, pr: 1.5, cursor: "pointer", border: `1px solid ${theme.palette.divider}`, borderRadius: '5px'}}
						            color="text.disabled"
						            onClick={handleAddPricingOption}
						>
							+ Add Option
						</Typography>
					}
					{packageData.options.map((option, index) => (
						<Grid container spacing={2} key={`option-${option.id}`} mb={1}>
							<Grid xs={4}>
								<TextField
									fullWidth
									label="Name"
									value={option.name}
									onChange={(e) => handlePricingChange(index, 'name', e.target.value)}
								/>
							</Grid>
							<Grid xs={4}>
								<TextField
									fullWidth
									label={`Price in Cents ${option.price_in_cents && ('- $' + fmt2Dec(option.price_in_cents / 100))}`}
									value={option.price_in_cents}
									onChange={(e) => handlePricingChange(index, 'price_in_cents', e.target.value)}
								/>
							</Grid>
							<Grid xs={3}>
								<TextField
									fullWidth
									label="License Days"
									value={option.license_days}
									onChange={(e) => handlePricingChange(index, 'license_days', e.target.value)}
								/>
							</Grid>
							<Grid xs={1}>
								<IconButton sx={{mt: 1}} onClick={()=>handleRemovePricingOption(index)}>
									<RemoveCircleOutline/>
								</IconButton>
							</Grid>
						</Grid>
					))}
				</Box>
			</Grid>
			<Dialog open={showProducts}>
				<DialogTitle>
					<LeftRight
						left={<Typography variant="h6">Add Product</Typography>}
						right={<Button onClick={()=>setShowProducts(false)}>
							Done
						</Button>}
					/>
				</DialogTitle>
				<DialogContent sx={{maxHeight: "500px", border: `1px solid ${theme.palette.divider}`}}>
					<List>
						{products && products.map((p,i) => {
							const sel = isProductSelected(p)

							return isProductSelected(p)
								? <ListItem
									key={`product-${p.id || i}`}
									selected={true}
									onClick={()=> handleRemoveProduct(p)}
									secondaryAction={
										<IconButton edge="end" >
											<RemoveCircleOutline />
										</IconButton>
									}
								>
									{p.name}
								</ListItem>
								: <ListItem
									key={`product-${p.id || i}`}
									onClick={()=> handleAddProduct(p)}
									secondaryAction={
										<IconButton edge="end" >
											<AddCircle />
										</IconButton>
									}
								  >
									{p.name}
								  </ListItem>
						})}
					</List>
				</DialogContent>
			</Dialog>
		</Grid>
	)
}

export default Package
