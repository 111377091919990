import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { initializeGA, trackPageView } from '../util/analytics'

const Analytics = () => {
	const location = useLocation()


	useEffect(() => {
		if (process.env.NODE_ENV !== "development") {
			initializeGA(process.env.REACT_APP_GTAG) // Initialize GA once when the component mounts
		}
	}, [])

	useEffect(() => {
		if (process.env.NODE_ENV !== "development") {
			trackPageView(location.pathname + location.search) // Track page view on location change
		}
	}, [location])

	return null // This component doesn't render anything
}

export default Analytics